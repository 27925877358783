import Clienticon from "./../assets/images/client.svg";
import Appointmenticon from "./../assets/images/appointment.svg";
import Treatmenticon from "./../assets/images/treatment.svg";
import Servicesicon from "./../assets/images/services.svg";
import Previouscon from "./../assets/images/arrowprevious.png";
import OverviewGradient from "./../assets/images/overview-gradient.png";
import Nexticon from "./../assets/images/arrownext.png";
import Appointmentgraph from "./../assets/images/graphappointment.svg";
import Gendergraph from "./../assets/images/gendergraph.svg";
import Avtarimg from "./../assets/images/avtarimg.png";
import { useEffect, useState } from "react";

import { handleDashboardData } from "../services/dashboardService";
import { showToastMessage } from "../helpers/toastr";
import { formatAmountSymbol } from "../helpers/formatAmountSymbol";
import BillingListing from "./appointments/billing/BillingListing";
import MembershipListing from "./SellMembership/MembershipListing";
import { useSelector } from "react-redux";
import checkPermission from "../helpers/checkPermission";
import ProductBillingListing from "./Product/ProductBilling/ProductBillingListing";

const Dashboard = ({ selectGlobalOrg }) => {
  const userData = useSelector((state) => state.userData);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState("Today");

  const [dateInputValue, setDateInputValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    if (
      (selection === "Date" && !dateInputValue) ||
      (selection === "CustomDate" && (!startDate || !endDate))
    ) {
      return;
    }

    fetchData();
  }, [selectGlobalOrg, selection, dateInputValue, startDate, endDate]);

  const fetchData = async () => {
    const orgID = Array.from(
      document.getElementById("orgId").selectedOptions,
      (option) => option.value
    );

    const formData = {
      orgID,
      filterType: selection,
      filterPeriod: dateInputValue,
      startDate: startDate,
      endDate: endDate,
    };

    setFormData(formData);

    try {
      const result = await handleDashboardData(formData);
      if (result.data) {
        setData(result.data.data);
      }
    } catch (error) {
      console.log(error);
      showToastMessage("error", error.response.data.message);
    }
  };

  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
    if (
      e.target.value === "Month" ||
      e.target.value === "Today" ||
      e.target.value === "Overall" ||
      e.target.value === "Year" ||
      (e.target.value === "Date" && dateInputValue) ||
      (e.target.value === "CustomDate" && startDate && endDate)
    ) {
      setSelection(e.target.value);
    }
  };

  const handleDateInputChange = (e) => {
    if (selection === "Date" && e.target.value) {
      setDateInputValue(e.target.value);
    }
  };

  const handleStartDateChange = (e) => {
    if (selection === "CustomDate" && e.target.value) {
      setStartDate(e.target.value);
    }
  };

  const handleEndDateChange = (e) => {
    if (selection === "CustomDate" && e.target.value) {
      setEndDate(e.target.value);
    }
  };

  return (
    <>
      <div className="page-header d-none">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-home"></i>
          </span>{" "}
          Dashboard
        </h3>
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              <span></span>Overview{" "}
              <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card card-filter">
            <div class="card-filter-wrap">
              <h4 class="">Overview</h4>
              <div class="card-filter-input">
                <div className="input-date">
                  <select
                    className="form-select"
                    value={selection}
                    onChange={handleSelectionChange}
                  >
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Date">Date</option>
                    <option value="Last-7-Days">Last 7 Days</option>
                    <option value="Last-14-Days">Last 14 Days</option>
                    <option value="Last-30-Days">Last 30 Days</option>
                    <option value="Overall">Overall</option>
                    <option value="CustomDate">Custom Date</option>
                  </select>
                </div>
                {selection === "CustomDate" ? (
                  <>
                    <div className="input-date">
                      <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        className="form-control"
                      />
                    </div>

                    <div className="input-date">
                      <input
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        className="form-control"
                      />
                    </div>
                  </>
                ) : selection === "Date" ? (
                  <div className="input-date">
                    <input
                      type="date"
                      value={dateInputValue}
                      onChange={handleDateInputChange}
                      className="form-control"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row marginbottom-25">
        <div class="col-xxl col-xl col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="card card-red card-red-height">
            <span class="card-red-gradient">
              <img src={OverviewGradient} alt="icon" />
            </span>
            <div class="card-header">
              <h4 class="card-title">Clients</h4>
              <div class="card-image">
                <svg
                  width="32"
                  height="36"
                  viewBox="0 0 32 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30.669 36H25.8733V29.3404C25.8733 29.1841 25.7497 29.0591 25.5957 29.0591C25.4416 29.0591 25.3181 29.1841 25.3181 29.3404V36H6.67744V29.3404C6.67744 29.1841 6.55415 29.0591 6.40012 29.0591C6.24593 29.0591 6.12251 29.1841 6.12251 29.3404V36H1.32675C1.18512 36 1.04942 35.9356 0.95675 35.8272C0.864204 35.7159 0.823731 35.5694 0.849825 35.4289C1.17046 33.4952 1.35242 32.03 1.49714 30.8524C2.02584 26.5749 2.15307 26.4135 6.11121 25.0889C6.89226 24.8274 7.82593 24.5147 8.95794 24.1021L15.8855 27.2258C15.9207 27.2418 15.9593 27.2487 15.9977 27.2487C16.037 27.2487 16.076 27.2421 16.1119 27.2258L23.0405 24.111C23.8237 24.4005 24.5089 24.6425 25.133 24.8634C29.7761 26.5074 29.8771 26.6361 30.5906 31.7107C30.7294 32.7067 30.9054 33.9461 31.1512 35.4347C31.1743 35.5783 31.1368 35.7248 31.0443 35.8329C30.9492 35.9356 30.8135 36 30.669 36ZM20.6487 20.559L20.6504 20.5576C18.8014 22.1025 17.1445 21.9042 14.9433 21.9042C13.5482 21.9042 12.2667 21.4047 11.263 20.573L11.3077 20.6099L9.30779 23.3745C9.11313 23.4469 8.91833 23.5184 8.72337 23.5891C7.87744 23.7994 3.35294 22.0673 4.3491 21.0719C4.37238 21.0485 7.04194 18.2241 7.01599 8.26223C7.01599 8.24478 7.01599 8.22704 7.01881 8.21259C7.48671 3.53052 11.3469 0 15.9985 0C20.6501 0 24.5126 3.53052 24.9781 8.21261C24.9781 8.22992 24.9809 8.24765 24.9809 8.26225C24.9578 18.2241 27.6244 21.0485 27.6535 21.0748C27.843 21.2579 27.8478 21.5938 27.6478 21.7841C27.5598 21.8671 25.9049 23.4143 23.2518 23.59C23.0648 23.521 22.878 23.4515 22.6912 23.3811L20.6487 20.559ZM10.0894 11.034V15.9973C10.0894 18.7105 12.2679 20.9197 14.9433 20.9197H16.9513C19.6266 20.9197 21.8051 18.7105 21.8051 15.9973V10.9752C20.3201 10.5741 18.1618 9.39021 17.6447 7.05518C15.1803 10.1667 11.4039 10.8789 10.0894 11.034ZM9.67962 23.8114L16 26.6624L22.3206 23.8194L20.5325 21.3489C19.5112 22.0542 18.2781 22.4667 16.9513 22.4667H14.9433C13.6449 22.4667 12.4366 22.0716 11.4283 21.3941L9.67962 23.8114Z"
                    fill="#FF1635"
                  />
                </svg>
              </div>
            </div>
            <div class="card-body">
              <div class="card-list">
                <span class="card-list-text">Cash</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {data.paymentModes?.count_cash || 0}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Card</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {data.paymentModes?.count_cc || 0}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">UPI</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {data.paymentModes?.count_gpay || 0}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Package</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {data.paymentModes?.count_pkg || 0}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">API</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {data.paymentModes?.count_upibills || 0}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Total Clients</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {(data.paymentModes?.count_cash || 0) +
                    (data.paymentModes?.count_cc || 0) +
                    (data.paymentModes?.count_gpay || 0) +
                    (data.paymentModes?.count_pkg || 0) +
                    (data.paymentModes?.count_upibills || 0) || 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl col-xl col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="card card-red card-red-height">
            <span class="card-red-gradient">
              <img src={OverviewGradient} alt="icon" />
            </span>
            <div class="card-header">
              <h4 class="card-title">Total Sales</h4>
              <div class="card-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  width="512"
                  height="512"
                >
                  <path d="M23,17.772c0,3.434-2.804,6.228-6.25,6.228h-1.098c1.076-.756,1.866-1.886,2.186-3.2,1.256-.447,2.162-1.629,2.162-3.028,0-4.566-4.262-9.772-8-9.772S4,13.206,4,17.772c0,1.399,.906,2.581,2.162,3.028,.32,1.314,1.11,2.444,2.186,3.2h-1.098c-3.446,0-6.25-2.794-6.25-6.228C1,12.576,5.146,6.34,10.318,5.188c-1.452-1.073-3.318-2.897-3.318-5.188h10c0,2.291-1.866,4.115-3.318,5.188,5.172,1.152,9.318,7.388,9.318,12.584Zm-7,1.728c0-1.528-.917-2.882-2.335-3.45l-2.215-.886c-.273-.109-.45-.37-.45-.665,0-.276,.224-.5,.5-.5h1c.276,0,.5,.224,.5,.5v.5h3v-.5c0-1.582-1.056-2.923-2.5-3.354v-1.146h-3v1.146c-1.444,.431-2.5,1.772-2.5,3.354,0,1.528,.917,2.882,2.335,3.45l2.215,.886c.273,.109,.45,.37,.45,.665,0,.276-.224,.5-.5,.5h-1c-.276,0-.5-.224-.5-.5v-.5h-3v.5c0,1.582,1.056,2.923,2.5,3.354v1.146h3v-1.146c1.444-.431,2.5-1.772,2.5-3.354Z" />
                </svg>
              </div>
            </div>
            <div class="card-body">
              <div class="card-list">
                <span class="card-list-text">Cash</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data.totalSales?.totalsales_cash)}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Card</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data.totalSales?.totalsales_cc)}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">UPI</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data.totalSales?.totalsales_gpay)}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">API</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data.totalSales?.totalsales_upibills)}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Total</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data.totalSales?.totalsales)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl col-xl col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="card card-red card-red-height">
            <span class="card-red-gradient">
              <img src={OverviewGradient} alt="icon" />
            </span>
            <div class="card-header">
              <h4 class="card-title">Services</h4>
              <div class="card-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="m3.638,9c-.618,0-1.098-.558-.989-1.166.359-2.001,1.356-3.861,2.855-5.266C7.439.753,9.966-.152,12.63.021c5.021.324,8.943,4.695,8.869,9.917-.04,2.818-2.389,5.063-5.208,5.063h-2.932c-.36.346-.846.561-1.384.561-1.105,0-2-.895-2-2s.895-2,2-2c.908,0,1.666.609,1.91,1.439h2.406c1.736,0,3.189-1.383,3.208-3.118.046-4.146-3.045-7.609-6.998-7.865-2.099-.13-4.101.578-5.63,2.011-1.209,1.133-1.988,2.584-2.261,4.178-.08.466-.5.795-.972.795Zm8.362,8c-3.695,0-6.892,2.292-7.955,5.702-.095.304-.04.635.149.891s.488.407.806.407h14c.318,0,.617-.151.806-.407s.244-.587.149-.891c-1.063-3.41-4.26-5.702-7.955-5.702Zm2.934-6h2.354c.135-.477.212-.979.212-1.5,0-3.038-2.462-5.5-5.5-5.5s-5.5,2.462-5.5,5.5c0,1.491.596,2.841,1.559,3.832.121-2.066,1.819-3.709,3.916-3.709,1.191,0,2.241.541,2.96,1.377Z" />
                </svg>
              </div>
            </div>
            <div class="card-body">
              <div class="card-list">
                <span class="card-list-text">Count</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {data.totalServices?.total_quantity || 0}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Total</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data.totalServices?.total_price)}
                </span>
              </div>
              {/* <div class="card-list">
                            <span class="card-list-text">Total</span>
                            <span class="card-list-dot">:</span>
                            <span class="card-list-value">4360</span>                         
                        </div> */}
            </div>
          </div>
        </div>
        <div class="col-xxl col-xl col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="card card-red card-red-height">
            <span class="card-red-gradient">
              <img src={OverviewGradient} alt="icon" />
            </span>
            <div class="card-header">
              <h4 class="card-title">Expenses</h4>
              <div class="card-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="m7,11c-.553,0-1-.448-1-1h-.268c-1.067,0-2.063-.574-2.598-1.499-.277-.478-.113-1.089.364-1.366.479-.278,1.091-.113,1.366.365.179.308.511.5.867.5h2.644c.344,0,.624-.28.624-.624,0-.307-.22-.565-.521-.616l-3.285-.548c-1.271-.211-2.193-1.3-2.193-2.588,0-1.447,1.177-2.624,2.624-2.624h.376c0-.552.447-1,1-1s1,.448,1,1h.268c1.067,0,2.063.575,2.598,1.5.277.478.113,1.089-.364,1.366-.481.276-1.092.113-1.366-.365-.179-.309-.511-.5-.867-.5h-2.644c-.344,0-.624.28-.624.624,0,.307.22.565.521.616l3.285.548c1.271.211,2.193,1.3,2.193,2.588,0,1.447-1.177,2.624-2.624,2.624h-.376c0,.552-.447,1-1,1Zm16.015,3.468l-5.196,5.943c-1.994,2.281-4.875,3.589-7.904,3.589h-5.414c-2.481,0-4.5-2.019-4.5-4.5v-4c0-2.481,2.019-4.5,4.5-4.5h7.857c.942,0,1.803.36,2.449.949l2.438-2.679c.699-.768,1.654-1.217,2.69-1.265,1.032-.046,2.029.309,2.797,1.008,1.565,1.428,1.692,3.875.282,5.455Zm-2.303-3.238c-.173-.158-.404-.241-.636-.229-.236.011-.453.113-.612.288l-3.497,3.843c-.218,1.584-1.475,2.884-3.093,3.115l-5.162.737c-.818.113-1.58-.453-1.697-1.273s.453-1.58,1.273-1.697l5.161-.737c.313-.045.551-.318.551-.636,0-.354-.288-.642-.643-.642h-7.857c-.827,0-1.5.673-1.5,1.5v4c0,.827.673,1.5,1.5,1.5h5.414c2.164,0,4.222-.935,5.646-2.563l5.206-5.955c.33-.371.302-.927-.055-1.252Zm-7.212-5.23h1.501v1.563c0,.863,1.043,1.295,1.653.685l3.063-3.063c.378-.378.378-.991,0-1.369l-3.063-3.063c-.61-.61-1.653-.178-1.653.685v1.563h-1.501c-.828,0-1.5.671-1.5,1.5s.672,1.5,1.5,1.5Z" />
                </svg>
              </div>
            </div>
            <div class="card-body">
              <div class="card-list">
                <span class="card-list-text">Cash</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data.totalExpense?.cashExpenseTotal)}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Bank</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data.totalExpense?.bankExpenseTotal)}
                </span>
              </div>
              {/* <div class="card-list">
                            <span class="card-list-text">Total</span>
                            <span class="card-list-dot">:</span>
                            <span class="card-list-value">4360</span>                         
                        </div> */}
            </div>
          </div>
        </div>
        <div class="col-xxl col-xl col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="card card-red card-red-height">
            <span class="card-red-gradient">
              <img src={OverviewGradient} alt="icon" />
            </span>
            <div class="card-header">
              <h4 class="card-title">Packages</h4>
              <div class="card-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="36px"
                  viewBox="0 -960 960 960"
                  width="32px"
                  fill="#5f6368"
                >
                  <path d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z" />
                </svg>
              </div>
            </div>
            <div class="card-body">
              <div class="card-list">
                <span class="card-list-text">Count</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {data.totalMembership?.total_quantity}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Total</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data?.totalMembershipPrice)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl col-xl col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="card card-red card-red-height">
            <span class="card-red-gradient">
              <img src={OverviewGradient} alt="icon" />
            </span>
            <div class="card-header">
              <h4 class="card-title">Product</h4>
              <div class="card-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="36px"
                  viewBox="0 -960 960 960"
                  width="32px"
                  fill="#5f6368"
                >
                  <path d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z" />
                </svg>
              </div>
            </div>
            <div class="card-body">
              <div class="card-list">
                <span class="card-list-text">Count</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {data.totalProduct?.total_quantity}
                </span>
              </div>
              <div class="card-list">
                <span class="card-list-text">Total</span>
                <span class="card-list-dot">:</span>
                <span class="card-list-value">
                  {formatAmountSymbol(data?.totalProduct?.total_price)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div class="row">*/}
      {/*<div class="col-md-12">*/}

      {/*<div class="row col-padding marginbottom-25">
            <div class="col-12">
                <div class="card">
                    <div class="card-header card-header-custom bg-white border-0 d-flex align-items-center justify-content-between">
                        <h3 class="bodytext-24 fontweight-600 text-dark mb-0">Overall Appointment</h3>
                        <div className="button-nav">
                             <button class="button button-primary button-pill button-pill-prev">
                                <img src={Previouscon} alt="icon"/>
                            </button>
                             <button class="button button-primary button-pill button-pill-next">
                                <img src={Nexticon} alt="icon"/>
                            </button>
                        </div>
                    </div>
                    <div class="card-body card-body-custom">
                        <img className="w-100" src={Appointmentgraph} alt="icon"/>
                    </div>
                </div>
            </div>
        </div>*/}
      {/*<div class="row col-padding marginbottom-25">
            <div class="col-lg-6 col-12">
                <div class="card">
                    <div class="card-header card-header-custom bg-white border-0 d-flex align-items-center justify-content-between">
                        <h3 class="bodytext-24 fontweight-600 text-dark mb-0">Gender</h3>
                    </div>
                    <div class="card-body">
                        <img className="w-100" src={Gendergraph} alt="icon"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="card">
                    <div class="card-header card-header-custom bg-white border-0 d-flex align-items-center justify-content-between">
                        <h3 class="bodytext-24 fontweight-600 text-dark mb-0">Stylists</h3>
                    </div>
                    <div class="card-body p-0 paddingleft-30 paddingright-30 paddingbottom-20">
                        <div class="avatar-item"t>
                            <div class="avatar-item-wrap">
                                <div class="avatar-item-content">
                                    <div class="icon"> 
                                          <img className="icon-xxl round-10 icontext-white" src={Avtarimg} alt="icon"/>
                                    </div>
                                    <div class="d-flex justify-content-between flex-wrap flex-column">
                                         <h4 class="bodytext-18 fontweight-600 text-dark mb-0 textelips">Churchill Gries</h4>
                                        <h4 class="bodytext-16 fontweight-400 text-dark mb-0 textelips">Clients</h4>
                                        <p class="mb-0 bodytext-12 fontweight-400 text-grey">1200</p>
                                    </div>
                                </div>
                                <div class="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal bg-dark round-5 padding-3"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                </div>
                            </div>
                        </div>
                        <div class="card card-mini bg-dark box-shadow-none shadow-none marginbottom-10">
                            <div class="card-body paddingleft-15 paddingright-15 paddingbottom-20 paddingtop-20">
                                <div class="d-flex justify-content-between gap-15">
                                    <div class="d-flex flex-nowrap gap-10">
                                        <div class="icon"> 
                                              <img className="icon-xxl round-10 icontext-white" src={Avtarimg} alt="icon"/>
                                        </div>
                                        <div class="d-flex justify-content-between flex-wrap flex-column">
                                             <h4 class="bodytext-18 fontweight-600 text-dark mb-0 textelips">Churchill Gries</h4>
                                            <h4 class="bodytext-16 fontweight-400 text-dark mb-0 textelips">Clients</h4>
                                            <p class="mb-0 bodytext-12 fontweight-400 text-grey">1200</p>
                                        </div>
                                    </div>
                                    <div class="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal bg-dark round-5 padding-3"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-mini bg-dark box-shadow-none shadow-none marginbottom-10">
                            <div class="card-body paddingleft-15 paddingright-15 paddingbottom-20 paddingtop-20">
                                <div class="d-flex justify-content-between gap-15">
                                    <div class="d-flex flex-nowrap gap-10">
                                        <div class="icon"> 
                                              <img className="icon-xxl round-10 icontext-white" src={Avtarimg} alt="icon"/>
                                        </div>
                                        <div class="d-flex justify-content-between flex-wrap flex-column">
                                             <h4 class="bodytext-18 fontweight-600 text-dark mb-0 textelips">Churchill Gries</h4>
                                            <h4 class="bodytext-16 fontweight-400 text-dark mb-0 textelips">Clients</h4>
                                            <p class="mb-0 bodytext-12 fontweight-400 text-grey">1200</p>
                                        </div>
                                    </div>
                                    <div class="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal bg-dark round-5 padding-3"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>*/}
      {/*</div>*/}


      {/*</div>*/}
      {checkPermission(userData, "View service-billings") && (
        <section className="section">
          {/* {console.log(formData,'formData')} */}
          <BillingListing
            filterData={JSON.stringify(formData)}
            type={"dashboard"}
          />
        </section>
      )}

      {checkPermission(userData, "View memberships") && (
        <section className="section">
          {/* {console.log(formData,'formData')} */}
          <MembershipListing
            filterData={JSON.stringify(formData)}
            type={"dashboard"}
          />
        </section>
      )}
      {checkPermission(userData, "View product") && (
        <div class="col-md-12">
          {/* {console.log(formData,'formData')} */}
          <ProductBillingListing
            filterData={JSON.stringify(formData)}
            type={"dashboard"}
          />
        </div>
      )}
    </>
  );
};
export default Dashboard;
