import axiosInstance from './axiosConfig';

// const handleAppointment = async (formData) => {
//   try {
//     const response = await axiosInstance.post('save-appointment', formData);
//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// const handleEditAppointment = async (uuid) => {
//   try {
//     const response = await axiosInstance.post('edit-appointment', uuid);
//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// const handleCustomerSearch = async (formData, orgId) => {
//   try {
//     const response = await axiosInstance.get(`get-customer-search?value=${formData}&orgId=${orgId}`);
//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

const handlePermissionListing = async () => {
  try {
    const response = await axiosInstance.get(`role-permissions`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handlePermission = async (formData) => {

    console.log(formData,'formDataformData');

    try {
      const response = await axiosInstance.post(`update-permissions`,formData);
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

// const handleDeleteAppointment = async (uuid) => {
//   try {
//     const response = await axiosInstance.get(`delete-appointment?uuid=${uuid}`);
//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// const handleAppointmentPDF = async (uuid) => {
//   try {
//     const response = await axiosInstance.get(`download-pdf?uuid=${uuid}`, {
//       responseType: 'blob',
//       headers: {
//         Accept: 'application/pdf',
//         'Content-Type': 'application/pdf',
//       },
//     });
//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

export {
    handlePermissionListing,
    handlePermission,
//   handleCustomerSearch,
//   handleAppointmentListing,
//   handleDeleteAppointment,
//   handleAppointmentPDF,
};
