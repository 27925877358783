
import loaderSVG from '../../../src/assets/images/spinning-dots.svg';

 const  Loading = () => {

    return (
        <div class="loader">
            <img src={loaderSVG} alt="Profile" />
        </div>
    );


}


export default Loading;