import axiosInstance from "./axiosConfig";

const handleProductBrand = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-product-brand?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleProductBrandListing = async (formData, page , per_page,searchText) => {
  try {
    const response = await axiosInstance.get(`get-product-brand-listing?orgId=${formData}&page=${page}&per_page=${per_page}&searchText=${searchText}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditProductBrand = async (id) => {
  try {
    const response = await axiosInstance.get(`edit-product-brand?id=${id}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteProductBrand = async (id,reason) => {
  try {
    const response = await axiosInstance.get(`delete-product-brand?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleProductBrand,
  handleProductBrandListing,
  handleEditProductBrand,
  handleDeleteProductBrand
};