import { Button } from 'primereact/button';
// import confirm from '../../helpers/confirm';

const ConfirmationPopupButton = ({handleDelete}) => {

    return (
        <>
        <Button className="icon-sm-medium round-100 bg-white icontext-red borderwidth-0 icon-sm-medium round-100 bg-white icontext-red borderwidth-0 p-button p-component ps-0" 
                    onClick={() => {
                        handleDelete() 
                    }}>
                        <i className="mdi mdi-delete-empty menu-icon bodytext-24"></i>
        </Button>
        </>
    )

}


export default ConfirmationPopupButton;