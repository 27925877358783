import React from 'react';
import ReactDOM from 'react-dom/client';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import "./assets/vendors/css/vendor.bundle.base.css";
import "./assets/css/style.css";
import App from './App';
import "./assets/vendors/mdi/css/materialdesignicons.min.css";
// import "./assets/index.js";
import { Provider } from 'react-redux';
import store from './store/store.js';
import './App.scss'
import './sass/custom-dash.scss'

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
