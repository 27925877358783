export function formatTime(timeString) {
     // Extract hours and minutes from the time string
  const [hours, minutes] = timeString.split(':');

  // Convert hours and minutes to numbers
  const hoursInt = parseInt(hours, 10);
  const minutesInt = parseInt(minutes, 10);

  // Determine AM or PM
  const period = hoursInt >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  const formattedHours = hoursInt % 12 || 12; // Handle midnight

  // Construct the formatted time string
  const formattedTime = `${formattedHours}:${minutes} ${period}`;

  return formattedTime;
  }