import axiosInstance from "./axiosConfig";

const handleCustomer = async (formData, reason) => {
  try {
    const response = await axiosInstance.post(`save-customer?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleCustomerListing = async (formData, page, per_page, searchText) => {
  try {
    const response = await axiosInstance.get(`get-customers-listing?orgId=${formData}&page=${page}&per_page=${per_page}&searchText=${searchText}`);
    console.log(response.data.data)
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditCustomer = async (uuid) => {
  try {
    const response = await axiosInstance.get(`edit-customer?uuid=${uuid}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteCustomer = async (uuid, reason) => {
  try {
    const response = await axiosInstance.get(`delete-customer?uuid=${uuid}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleOutStandingBalance = async (orgId,formdata,uuid) => {
  try {
    const response = await axiosInstance.get(`add-outstanding?orgId=${orgId}&paid_amount=${formdata}&uuid=${uuid}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export {
  handleCustomer,
  handleCustomerListing,
  handleEditCustomer,
  handleDeleteCustomer,
  handleOutStandingBalance
};