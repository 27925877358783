export function validatePhone(e) {
  // Check if the entered key is a number
  const isValidInput = /^\d$/.test(e.key);
  // Check if the length of the phone number is already 10 characters
  const isMaxLength = e.target.value.length >= 10;

  // Allow Ctrl+A (select all), Ctrl+V (paste), Ctrl+X (cut)
  if ((e.ctrlKey && (e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x')) ||
      // Allow Backspace
      e.key === 'Backspace' ||
      // Allow valid input and not exceeding max length
      (isValidInput && !isMaxLength)) {
      return; // allow default behavior
  }

  // Prevent default for all other cases
  e.preventDefault();
}
