import axiosInstance from "./axiosConfig";

const handlePackage = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-package?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handlePackageListing = async (formData, page, per_page, searchText) => {
  try {
      const response = await axiosInstance.get(`get-package-listing?orgId=${formData}`, {
          params: {
              page: page,
              per_page: per_page,
              searchText: searchText,
          },
      });
      return response;
  } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
  }
};



const handleEditPackage = async (id) => {
  try {
    const response = await axiosInstance.get(`edit-package?id=${id}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeletePackage = async (id,reason) => {
  try {
    const response = await axiosInstance.get(`delete-package?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handlePackage,
  handlePackageListing,
  handleEditPackage,
  handleDeletePackage
};