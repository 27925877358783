export function formatAmount(string) {
    // Ensure string is converted to a number
    const amount = parseFloat(string);
    // Check if amount is a valid number
    if (!isNaN(amount)) {
        // Format amount to have two decimal places
        return amount.toFixed(2);
    } else {
        // Return original string if not a valid number
        return '0.00';
    }
}
