export function discountValidate(e, hasAdminRole, hasCoOwnerRole) {
  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Home", "End", "Tab"];
  const discountMode = document.getElementById("discount_mode").value;
  const currentValue = e.target.value;
  
  // Allow only digits and allowed control keys
  if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
    return;
  }
  
  const newValue = parseInt(currentValue + e.key, 10);

  if (discountMode === '%') {
    // Set max discount to 100% if user has admin or co-owner role, else limit to 50%
    const maxDiscount = hasAdminRole || hasCoOwnerRole ? 100 : 50;

    // Prevent the percentage from exceeding the maximum allowed
    if (newValue > maxDiscount) {
      e.preventDefault();
      e.target.value = maxDiscount.toString(); // Auto-correct to max allowed discount
    }

    // Limit to a maximum of 3 characters for percentage values
    if (currentValue.length >= 3 && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  } else if (discountMode === '₹') {
    // Allow switching from ₹ to % by resetting value when % is entered
    if (e.key === '%') {
      e.target.value = ""; // Reset value for switch to %
      return;
    }

    // Ensure rupee discount does not exceed 50% of subtotal
    const subtotal = parseFloat(document.getElementById("subTotal").value);
    if (newValue > subtotal / 2) {
      e.preventDefault();
      e.target.value = (subtotal / 2).toFixed(0); // Auto-correct to max rupee discount
    }
  }
}
