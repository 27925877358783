import axiosInstance from "./axiosConfig";

const handleProduct = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-product?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleProductListing = async (formData, status,page,per_page,searchText) => {
  try {
    const response = await axiosInstance.get(`get-product-listing?orgId=${formData}&status=${status}&page=${page}&per_page=${per_page}&searchText=${searchText}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditProduct = async (id) => {
  try {
    const response = await axiosInstance.get(`edit-product?id=${id}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteProduct = async (id,reason) => {
  try {
    const response = await axiosInstance.get(`delete-product?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleProduct,
  handleProductListing,
  handleEditProduct,
  handleDeleteProduct
};