import { formatDate } from "../../helpers/formatDate";
import { getCurrentDate } from "../../helpers/getCurrentDate";
import styles from "./styles";
import ReactLogo from '../../assets/images/HairRaiserz.png'

const JobCard = ({data,org}) => {

    console.log(org,'orgrgrgrg');

    const services = [
        "Ironing",
        "Hair Cut",
        "Hair Colour",
        "Hair Spa",
        "Head Massage",
        "Threading",
        "Bleach",
        "Cleansing",
        "Facial",
        "Manicure",
        "Pedicure",
        "Waxing",
        "Shave/ BT",
        "Party Makeup",
        "Hair Do",
        "Engagement",
        "Bridal",
        "Reception"
    ];
    
    const loopItrate = new Array(25).fill(null);
    
    // console.log(loopItrate);
    

    // console.log(loopItrate);

    // console.log(data,'data');

    const date = getCurrentDate();

    // console.log(date,'date');

    return (
        <>

      
          {/* HR Job Card */}
          <div className="invoice hr-job-card"  style={styles.invoice}>
      <table style={styles.width}>
        <tr>
          <td>
            <table style={styles.width}>
              <tr>
              {/* <td style={styles.textCenter}> <img src={'static/media/HairRaiserz.594bc1684a028656a861.png'} /> </td> */}
                <td style={styles.textCenter}> <img src={ReactLogo} /> </td>
                
              </tr>
             
            </table>
          </td>
        </tr>
        <tr>
          <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
            <table style={styles.width}>
            <tr>
                <td>
                  <p className="billno" style={styles.billno}>Bill No: </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="billno" style={styles.billno}>Client name: </p>
                </td>
              </tr>
            </table>
            <table style={styles.width}>
              <tr>
                <td style={{width: '50%'}}>
                  <p className="billno" style={styles.billno}>Phone Number:</p>
                </td>
               
              </tr>
            </table>
            <table style={styles.width}>
              <tr>
               
                <td style={{width: '40%'}}>
                  <p className="billno" style={styles.billno}>Date of visit: {formatDate(date)}</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="invoice-table" style={styles.width}>
                <thead className="theadbg"style={styles.theadbg}>
                  <tr>
                    <td className="theadtd" style={{width: "3.33%", ...styles.theadtd}}></td>
                    <th className="theadth"style={{width: "32.33%", ...styles.theadth}}>Service</th>
                    
                    <th className="theadth"style={{ width: "32.33%", ...styles.theadth, ...styles.textLeft }}>Amount</th>
                  </tr>
                </thead>
                <tbody>

               

            {loopItrate.map((_,index) => (
            <tr key={index} className="" style={styles.borderb}>
                <td className="theadtd" style={{ width: "3.33%", ...styles.theadtd }}>{index+1}</td>
                <td className="theadtd" style={{ width: "32.33%", ...styles.theadtd }}>{services[index]}</td>
                
                <td className="theadtd" style={{ width: "32.33%", ...styles.theadtd, ...styles.textLeft }}></td>
            </tr>
            ))}

                    
                  
                </tbody>
              </table>
              <table style={styles.width}>
                <tr className="mt-3">
                  <td style={{width: '100%',textAlign: 'left', ...styles.theadth}}>
                    <p>We are fully Satisfied with the services offered to me and have no complaints whatsoever
                    Tress Lounge shall not be responsible for any complications later on.</p>
                  </td>
                 
                </tr>
              </table>
              <div className="bottom-sign">
                    <div className="row">
                      <div className="col-4">

                      </div>
                      <div className="col-8">
                      <tr>
                        <td>
                          <p className="billno" style={styles.billno}>Client Name: </p>
                        </td>
                      </tr>
                      </div>
                    </div>
              </div>
          </td>
        </tr>
      </table>
      </div>

        </>

    );

}


export default JobCard;