const SearchFilter = ({setSearchValue,searchValue}) => {

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    return (
        <>
            <input
                type="search"
                name="search"
                id="search"
                className="table-data-search form-control w-auto"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
            />
        </>
    );
};

export default SearchFilter;


