import React, { useState } from 'react';
import styled from 'styled-components';

const ReasonTooltip = ({ reason }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const Tooltip = styled.div`
        position: absolute;
        background-color: black;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        white-space: normal;
        z-index: 10;
        top: 100%;
        left: -50%;
        transform: translateX(-50%);
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        max-width: 500px; /* Set your desired max width */
        max-height: 500px; /* Set a max height for the tooltip */
        overflow-y: auto; /* Enable vertical scrolling */
        overflow-x: hidden; /* Prevent horizontal scrolling */
        text-overflow: ellipsis;
        word-wrap: break-word;
    `;

    const truncatedText = reason.length > 50 ? `${reason.substring(0, 50)}...` : reason;

    return (
        <div
            style={{ display: 'inline-block', position: 'relative' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {truncatedText}
            </span>
            {isHovered && 
                <Tooltip>{reason}</Tooltip>
            }
        </div>
    );
};

export default ReasonTooltip;
