import React, { useEffect, useState } from "react";
import { Listing } from "../../../components/appointments/Listing";
import { handleBilling, handleCustomerSearch } from "../../../services/billingService";
import { showToastMessage } from "../../../helpers/toastr";
import { handleEditCustomer } from "../../../services/customerServices";
import { CustomerDetail } from "../../../components/appointments/CustomerDetail";
import { handleStaffListing } from "../../../services/staffServices";
import { formatAmount } from "../../../helpers/formatAmount";
import { NavLink, useNavigate } from "react-router-dom";
import { validatePhone } from "../../../helpers/validatePhone";
import { MultipleProductListing } from "../../../components/products/MultipleProductListing";
import { handleEditProduct, handleProductListing } from "../../../services/productService";
import { handleProductBilling } from "../../../services/productBillingService";
import { getCurrentDate } from "../../../helpers/getCurrentDate";
import { getCurrentTime } from "../../../helpers/getCurrentTime";
import { debounce } from 'lodash';
import { selectOptions } from "../../../helpers/paymentOption";
import { useSelector } from "react-redux";
import checkPermission from "../../../helpers/checkPermission";

const ProductBilling = ({ selectGlobalOrg }) => {

    const navigate = useNavigate();
    const userData = useSelector((state) => state.userData);
    const [popupID, setPopupID] = useState('');

    const [showDiv, setShowDiv] = useState('none');
    const [showGender, setShowGender] = useState('');
    const [showDetail, setShowDetail] = useState('none');
    const [searchdata, setSearchData] = useState([]);
    const [value, setValue] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [products, setProducts] = useState([]);
    const [btn, setBtn] = useState(false);
    const [errors, setErrors] = useState({});
    const [nameInputFocus, setNameInputFocus] = useState(false);
    const [contactInputFocus, setContactInputFocus] = useState(false);

    const [isVisible, setIsVisible] = useState([false]);

    const isMultiPaymentValid = () => {
        return fields.every(field => (field.selectValue !== "" || field.selectValue.trim() !== "") );
      };

      
      const isMultiPaymentAmountValid = () => {
        return fields.every(field => (((field.inputValue !== "" && field.inputValue > 0) && field.selectValue!=="") || (field.inputValue.trim() !== "" && field.selectValue!=="")));
      };

    const [formData, setFormData] = useState({

        // customer
        name: '',
        phone: '',
        email: '',
        gender: 'male',
        orgId: '',
        // wallet:0,
        refId: '',
        type: '',
        date: getCurrentDate(),
        time: getCurrentTime(),

        // Service Billing
        billingRemark: '',
        subTotal: '',
        paymentMode: '',

        multiplePaymentMethods: "",
        // Multiple billing service
        products: [
            {
                productId: '',
                staffId: '',
                // coStaffId:[],
                quantity: '1',
                price: '',
                total: '',
            }
        ]
    });

    const [fields, setFields] = useState([
        { selectValue: "", inputValue: "" },
    ]);

    const handleSelectChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].selectValue = e.target.value;
        if(updatedFields[index].selectValue===""){
            updatedFields[index].inputValue=""
        }
        // setFormData({...formData, multiplePaymentMethods:updatedFields})
        setFields(updatedFields);
        const newIsVisible = [...isVisible];
        newIsVisible[index] = true;
        setIsVisible(newIsVisible);
    };

    const handleMultiInputChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].inputValue = e.target.value;
        if (updatedFields[index].selectValue !== "") {
          setFields(updatedFields);
        }
    
    };

    const addSelectBox = () => {
        setFields([...fields, { selectValue: "", inputValue: "" }]);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        }

        if (!isMultiPaymentValid()) {
            newErrors.paymentMode = "Payment mode is required";
            isValid = false;
          setErrors(newErrors);
            return isValid;
          }
          if (!isMultiPaymentAmountValid()) {
            newErrors.paymentMode = "Amount is required.";
            isValid = false;
          setErrors(newErrors);
            return isValid;
          }

        // Validate phone
        const phoneValue = formData.phone.trim();

        if (!phoneValue) {
            newErrors.phone = "Phone is required";
            isValid = false;
        } else if (phoneValue.length !== 10) {
            newErrors.phone = "Phone number should be 10 numbers";
            isValid = false;
        } else if (!phoneValue.match(/^[6-9]\d{9}$/)) {
            newErrors.phone = "Invalid phone number format";
            isValid = false;
        }



        setErrors(newErrors);



        return isValid;
    };

    const handleInputChange = (e) => {

        const { name, value, type, checked } = e.target;
        const processedValue = type === 'number' ? parseFloat(value) : value;


        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: processedValue,
        }));



        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };



    const handleKeyUp = (event) => {
        const inputValue = event.target.value;
        setValue(inputValue);
    };

    const searchCustomerNumber = async (value) => {

        const orgID = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleCustomerSearch(value, orgID);

            if (result.data) {
                if (result.data?.data.length > 0) {
                    setSearchData(result.data?.data)
                    setShowDiv('block')
                } else {
                    setShowDiv('none')
                    setSearchData([]);
                }
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    // if (value) {
    //     searchCustomerNumber(value)
    // } else {
    //     setShowDiv('none')
    //     setSearchData([]);
    //     setValue('')
    // }
    //     }, 1000);
    //     return () => clearTimeout(delayDebounceFn);
    // }, [value, selectGlobalOrg]);

    useEffect(() => {

        const debouncedFetchData = debounce(searchCustomerNumber, 300);

        const fetchDataOnChange = () => {
            debouncedFetchData(value);
        };

        if (value) {
            fetchDataOnChange(value)
        } else {
            setShowDiv('none')
            setSearchData([]);
            setValue('')
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [value, selectGlobalOrg]);



    const handleCustomerClick = async (id) => {

        try {
            const result = await handleEditCustomer(id);

            if (result.data) {
                setSelectedCustomer(result.data?.data)
                setShowDetail('block')
                setShowDiv('none')
                setShowGender('none')
            } else {
                setShowGender('')
                setShowDiv('none')
                setShowDetail('none')
                setSelectedCustomer([]);
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    useEffect(() => {

        if (selectedCustomer && selectedCustomer.length != 0) {



            setFormData({
                name: selectedCustomer.name,
                phone: selectedCustomer.phone_number,
                uuid: selectedCustomer.uuid,
                email: selectedCustomer.email,
                gender: selectedCustomer.gender,
                dob: selectedCustomer.dob,
                orgId: selectedCustomer.org_id,
                customerId: selectedCustomer.id,
                wallet: selectedCustomer.wallet,
                type: 'add',
                date: getCurrentDate(),
                time: getCurrentTime(),
                refId: '',

                billingRemark: '',
                subTotal: '0.00',
                billingTax: '0.00',
                paymentMode: '',
                grandTotal: '0.00',
                roundOffGrandTotal: 0,
                taxEnabled: false,

                // Multiple billing service
                products: [
                    {
                        productId: '',
                        staffId: '',
                        quantity: '1',
                        price: '',
                        total: '',
                    }
                ]

            });
        } else {
            const newOrgId = document.getElementById('orgId').value;
            setFormData({

                // customer
                name: '',
                phone: '',
                email: '',
                gender: 'male',
                orgId: newOrgId,
                refId: '',
                type: 'add',
                wallet: 0,
                date: getCurrentDate(),
                time: getCurrentTime(),

                // Service Billing
                billingRemark: '',
                subTotal: '0.00',
                billingTax: '0.00',
                paymentMode: '',
                grandTotal: '0.00',
                roundOffGrandTotal: 0,
                taxEnabled: false,

                // Multiple billing service

                products: [
                    {
                        productId: '',
                        staffId: '',
                        quantity: '1',
                        price: '',
                        total: '',
                    }
                ]
            });
        }

    }, [selectedCustomer]);


    useEffect(() => {

        fetchStaff();
        fetchProducts();

    }, [selectGlobalOrg]);

    //   useEffect for fetch staff members

    const fetchStaff = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleStaffListing(initialOrgIds, 'employee', '0');

            if (result.data) {
                setStaffData(result.data?.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    const fetchProducts = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        const status = 0;

        try {
            const result = await handleProductListing(initialOrgIds, status);
            if (result?.data) {
                setProducts(result?.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }


    const addRow = () => {
        setFormData({
            ...formData,
            products: [...formData.products, { productId: '', staffId: '', quantity: '1', price: '0.00', total: '0.00' }]
        });
    };

    const removeRow = (index) => {
        setFormData(prevState => {
            if (prevState.products.length <= 1) {
                // Show toast message indicating that at least one service is required
                showToastMessage("error", "At least one product is required");
                return prevState; // Don't modify state
            }

            const updatedProducts = [...prevState.products];
            updatedProducts.splice(index, 1);



            let subtotal = 0;

            updatedProducts.forEach(service => {

                subtotal += service.price * service.quantity;

            });


            // grandTotal -= discountAmt;

            // let grand = roundOffAmount(grandTotal)

            return {
                ...prevState,
                products: updatedProducts,
                subTotal: formatAmount(subtotal),

            };

        });
    };


    const calculateTotalForRow = (quantity, price, tax) => {
        const totalPrice = parseFloat(quantity) * parseFloat(price);
        // const totalTax = totalPrice * (parseFloat(tax) / 100);
        return formatAmount(totalPrice);
    };

    const handleFieldChange = (fieldName, value, index) => {
        console.log(fieldName, value, index, 'index');

        // let amountToDeduct;

        setFormData(prevState => {
            const updatedProducts = [...prevState.products];

            if (index !== undefined) {
                updatedProducts[index] = {
                    ...updatedProducts[index],
                    [fieldName]: value,
                    total: calculateTotalForRow(
                        fieldName === 'quantity' ? value : updatedProducts[index]?.quantity,
                        fieldName === 'price' ? value : updatedProducts[index]?.price,
                        // '18'
                    ),
                    // tax:'18',
                };
            }

            let subtotal = 0;

            updatedProducts.forEach(service => {

                subtotal += service.price * service.quantity;

            });


            // grandTotal -= discountAmt;

            // let grand = roundOffAmount(grandTotal)

            return {
                ...prevState,
                products: updatedProducts,
                subTotal: formatAmount(subtotal),
                // grandTotal: formatAmount(grandTotal),
                // roundOffGrandTotal:grand,
                // discount: discount,
                // discountedAmount: formatAmount(discountAmt)
            };
        });


    };


    const handleProduct = async (option, index) => {
        const id = option.value;
        if (!id) {
            return false;
        }
        try {
            const result = await handleEditProduct(id);
            if (result.data) {
                const newData = result.data?.data;

                setFormData(prevState => {
                    const updatedProducts = [...prevState.products];

                    updatedProducts[index] = {
                        ...updatedProducts[index],
                        productId: newData.id,
                        price: newData.price,
                        quantity: '1',
                        total: calculateTotalForRow('1', newData.price)
                    };

                    let subtotal = 0;

                    updatedProducts.forEach(service => {

                        subtotal += service.price * service.quantity;

                    });

                    return {
                        ...prevState,
                        products: updatedProducts,
                        subTotal: formatAmount(subtotal),
                    };
                });

            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        }
    };

    const options = [{ value: "", label: "Select Product" }];
    if (products && products.length > 0) {
        const groupedProducts = {};

        products.forEach((el) => {
            if (el.deleted_at === null) {
                if (!groupedProducts[el.product_id]) {
                    groupedProducts[el.product_id] = [];
                }

                groupedProducts[el.product_id].push({
                    value: el.id,
                    label: el.name,
                    cat_id: el.get_brand_name?.name
                });
            }
        });

        for (const product in groupedProducts) {
            options.push({
                label: groupedProducts[product][0].cat_id,
                options: groupedProducts[product],
            });
        }
    }


    // console.log(products,'optionsoptionsoptions');

    const staffOptions = [{ value: "", label: "Select Staff" }];
    if (staffData && staffData.length > 0) {
        staffData.forEach((el) => {
            if (!el.deleted_at && el.role?.role === 'employee') { // Check if deleted_at is null or undefined and role is 'employee'
                staffOptions.push({
                    value: el.id,
                    label: el.name,
                });
            }
        });
    }




    const handleSubmit = async (e) => {

        if (validateForm()) {

            setBtn(true)

            try {
                const newFormData = { ...formData, multiplePaymentMethods: fields }
                setFormData(newFormData);
                const result = await handleProductBilling(newFormData);

                if (result.data) {
                    showToastMessage('success', result.data.message)
                    const newOrgId = document.getElementById('orgId').value;


                    navigate('/product-billing-listing')

                    setBtn(false)



                }
            } catch (error) {
                setBtn(false)
                console.log(error);
                if (error.response.status === 404) {
                    const errors = error.response.data.message
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((key) => {
                            errors[key].forEach((message) => {
                                showToastMessage('error', message)
                            });
                        });
                    }
                } else {
                    showToastMessage('error', error.response.data.message)
                }
            }

        }


    }
    const triggerBackspace = (e) => {

        const key = e.keyCode || e.which;


        if (key === 8) {
            setSelectedCustomer([]);
        }


        if (e.ctrlKey && key === 88) {
            setSelectedCustomer([]);
        }
    };

    return (

        <>

            <div className="card">
                <div className="card-header bg-transparent">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="card-title heading-h3 mb-0">Product Billing</h2>
                        </div>
                        <div className="col-auto">
                            {checkPermission(userData, 'Update product') && (
                                <NavLink to='/product-billing-listing' className="btn btn-primary mt-2">View All Invoices</NavLink>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form id="billingform" autoComplete="off">
                        <div className="row">
                            <div className="col-xl-8 col-lg-7 col-md-12">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Mobile Number *</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="phone"
                                                value={formData.phone}
                                                onKeyDown={(e) => {
                                                    validatePhone(e);
                                                    triggerBackspace(e);
                                                }}
                                                onChange={(e) => handleInputChange(e)}
                                                onKeyUp={handleKeyUp}
                                                onFocus={()=>{setContactInputFocus(true), setNameInputFocus(false)}}
                                                className="form-control cust_mob" id="phone" placeholder="Enter Phone Number" />
                                            {contactInputFocus && <ul className="typeahead dropdown-menu"
                                                style={{ display: showDiv }}>
                                                <Listing
                                                    searchdata={searchdata}
                                                    handleCustomerClick={(id) => handleCustomerClick(id)}
                                                />
                                            </ul>}
                                            {errors.phone && (
                                                <div className="text-danger">{errors.phone}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Customer Name *</label>
                                            <input
                                                type="text"
                                                name="name"
                                                required=""
                                                className="form-control required name"
                                                value={formData.name}
                                                onChange={(e) => handleInputChange(e)}
                                                onKeyUp={handleKeyUp}
                                                onFocus={()=>{setContactInputFocus(false), setNameInputFocus(true)}}
                                                id="name"
                                                placeholder="Enter Customer Name" />
                                            {nameInputFocus && <ul className="typeahead dropdown-menu"
                                                style={{ display: showDiv }}>
                                                <Listing
                                                    searchdata={searchdata}
                                                    handleCustomerClick={(id) => handleCustomerClick(id)}
                                                />
                                            </ul>}
                                            {errors.name && (
                                                <div className="text-danger">{errors.name}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6" style={{ display: showGender }}>
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">Gender</label>
                                            <select
                                                name="gender"
                                                id="gender"
                                                value={formData.gender}
                                                onChange={(e) => handleInputChange(e)}
                                                className="cust_gender form-control form-select">
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">How did you reach us?</label>
                                            <select
                                                className="form-control form-select"
                                                name="refId"
                                                value={formData.refId}
                                                onChange={(e) => handleInputChange(e)}
                                            >
                                                <option value="">Select Reference</option>
                                               
                                                <option value="insta">Instagram</option>
                                                <option value="fb">Facebook</option>
                                                <option value="walkin">Walkin</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="Customer">Date</label>
                                        <input 
                                            className="form-control"
                                            type="date"
                                            name="date"
                                            value={formData.date}
                                            onChange={(e) => handleInputChange(e)}
                                            placeholder="Date" />
                                    </div>
                                 </div>
                                 <div className="col-sm-6 col-md-6">
                                 <div className="form-group">
                                        <label htmlFor="Customer">Time</label>
                                        <input  
                                            className="form-control"
                                            type="time"
                                            name="time"
                                            value={formData.time}
                                            onChange={(e) => handleInputChange(e)}
                                            placeholder="Time" />
                                    </div>
                                 </div> */}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 col-md-12">
                                <blockquote className="blockquote blockquote-primary customer_detail round-10 " >
                                    <CustomerDetail
                                        selectedCustomer={selectedCustomer}
                                    />
                                </blockquote>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-end">
                                <button type="button" onClick={addRow} id="btn_add_row" className="btn btn-primary btn-icon-left"><i className="fa fa-plus"></i> Add Product</button>
                            </div>

                            <div className="col-md-12 mt-4">
                                <div className="table-billing-form" id="item_table">
                                    <div className="row">

                                        {formData.products.map((value, index) => (
                                            <MultipleProductListing
                                                key={index}
                                                index={index}
                                                value={value}
                                                services={options} // Pass your options here
                                                staffData={staffOptions} // Pass your staffOptions here
                                                handleProduct={handleProduct}
                                                handleFieldChange={handleFieldChange}
                                                removeRow={removeRow}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-between mt-2">
                            <div className="col-md-12" id="button_add">
                                <div className="form-group">
                                    <label for="billing_remark">Billing Remark</label>
                                    <textarea
                                        className="form-control billing_remark"
                                        id="billing_remark"
                                        name="billingRemark"
                                        value={formData.billingRemark}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-md-12 mt-4" id="package">
                                <div className="overflow-auto">
                                <table className="table table-bordered table-billing">
                                    <tbody>
                                        <tr>

                                            <th style={{ width: '50%' }}>Sub Total</th>
                                            <td className="text-right">
                                                <span id="subTotal">{formData?.subTotal || 0.00}</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Payment Mode</th>
                                            <td className="text-right">
                                                {fields.map((field, index) => (
                                                    <>
                                                        <div className={`row flex-nowrap ${index > 0 ? "mt-3" : ""}`}>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <select
                                                                                className="payment_mode form-select form-control form-field-w"
                                                                                name="paymentMode"
                                                                                value={field.selectValue}
                                                                                onChange={(e) => {
                                                                                    handleSelectChange(e, index);
                                                                                    handleInputChange(e);
                                                                                }}
                                                                            >
                                                                                <option value="">Select Payment Mode</option>
                                                                                {selectOptions(null, index)}
                                                                                {/* <option value="cash">Cash</option>
                                                                            <option value="cc">Card</option>
                                                                            <option value="gpay">Gpay/UPI</option> */}
                                                                        </select>
                                                                    </div>
                                                                    {isVisible[index] === true && field.selectValue!=="" && (
                                                                        <div className="col">
                                                                            <input
                                                                            min="1"
                                                                            type="number"
                                                                            className="form-control"
                                                                            onChange={(e) =>
                                                                                { 
                                                                                    handleMultiInputChange(e, index)
                                                                                    errors.paymentMode = ""
                                                                                }
                                                                            }
                                                                        />
                                                                        </div> 
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {(fields.length > 1 && index > 0) ? (
                                                                <div className="col-auto">
                                                                    <button
                                                                        type="button"
                                                                        onClick={(e) =>
                                                                            {
                                                                            const updatedFields = fields.filter((_, i) => i !== index);
                                                                            setFields(updatedFields);
                                                                            }
                                                                        }
                                                                        id="btn_remove_row"
                                                                        className="button button-primary btn-icon"
                                                                    >
                                                                        <i className="fa fa-minus"></i>
                                                                    </button> 
                                                                    
                                                                </div>
                                                                ) : (
                                                                <div className="col-auto">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                        setFields([...fields, { selectValue: "", inputValue: "" }])
                                                                        }
                                                                        id="btn_row"
                                                                        className="button button-primary btn-icon"
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                                )
                                                            }
                                                            </div>   
                                                    </>
                                                ))}
                                                {errors.paymentMode && (
                                                    <div className="text-danger">
                                                        {errors.paymentMode}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>






                                        {/* <tr className="amount_due">
                                        <th>Grand Total:</th>
                                        <td className="text-right">
                                            <span className="currencySymbol" style={{ display: 'inline-block' }} ></span>
                                            <span id="grandTotal">{formatAmount(formData?.roundOffGrandTotal) }</span>
                                           
                                        </td>
                                    </tr> */}
                                    </tbody>
                                </table>
                                </div>
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={btn}
                                    className="btn btn-primary table-billing-btn" >Save Bill</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ProductBilling;
