
import React, { useEffect, useState } from "react";
// import { Listing } from "../../../components/appointments/Listing";
import { handleBilling, handleCustomerSearch, handleEditBilling } from "../../../services/billingService";
import { showToastMessage } from "../../../helpers/toastr";
import { handleEditCustomer } from "../../../services/customerServices";
// import { CustomerDetail } from "../../../components/appointments/CustomerDetail";
import { TableListing } from "../../../components/appointments/TableListing";
import { handleStaffListing } from "../../../services/staffServices";
import { handleEditService, handleServiceListing } from "../../../services/service";
import { formatAmount } from "../../../helpers/formatAmount";
import { roundOffAmount } from "../../../helpers/roundOffAmount";
import { discountValidate } from "../../../helpers/discountValidate";
import { CustomerDetail } from "../../../components/appointments/CustomerDetail";
import { getCurrentDate } from "../../../helpers/getCurrentDate";
import { getCurrentTime } from "../../../helpers/getCurrentTime";
import { selectOptions } from "../../../helpers/paymentOption";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";



const EditBilling = ({ selectGlobalOrg }) => {

    const navigate = useNavigate();

    const uuid = useParams('uuid');
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [discountLimit, setDiscountLimit] = useState('40');
    const [showDiscount, setshowDiscount] = useState('none');

    const [billingData, setBillingData] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [services, setServices] = useState([]);
    const [btn, setBtn] = useState(false);
    const [showWallet, setShowWallet] = useState('none');

    const location = useLocation();
    const reason = location.state?.reason;
    const [isVisible, setIsVisible] = useState([false]);
    const [formData, setFormData] = useState({

        // customer
        name: '',
        phone: '',
        email: '',
        gender: '',
        orgId: '',
        refId: '',
        wallet: 0,
        type: '',
        date: getCurrentDate(),
        time: getCurrentTime(),


        // Service Billing
        billingRemark: '',
        subTotal: '',
        billingTax: '',
        discountSymbol: '%',
        discountedAmount: '',
        discount: '',
        specialDiscount: '',
        paymentMode: '',
        multiplePaymentMethods: "",
        grandTotal: '',
        walletStatus: false,
        taxEnabled: false,
        walletAmount: '0.00',
        roundOffGrandTotal: 0,

        // Multiple billing service
        services: [
            {
                serviceId: '',
                staffId: '',
                coStaffId: [],
                // tax:'18',
                quantity: '1',
                price: '',
                total: '',
            }
        ]
    });

    const [fields, setFields] = useState([
        { selectValue: "", inputValue: "" },
    ]);
    

    useEffect(() => {
        if (billingData?.multiple_payment_types && billingData?.multiple_payment_types.length > 0) {
            const tempFields = billingData?.multiple_payment_types.map(item => ({
                selectValue: item?.payment_mode,
                inputValue: item?.price
            }));
            setFields(tempFields);
            const tempIsVisible = Array(tempFields.length).fill(true);
            setIsVisible(tempIsVisible);
        }
    }, [billingData?.multiple_payment_types]);

    useEffect(() => {
        if (billingData && billingData.length != 0) {

            if (selectGlobalOrg && (billingData.org_id != selectGlobalOrg)) {

                showToastMessage('error', 'Organizaton changed and that record not exits in this record')
                navigate('/appointment-listing')
            }

            let servicesData;

            if (billingData.get_multiple_services && billingData.get_multiple_services.length > 0) {
                servicesData = billingData.get_multiple_services.map(service => ({
                    serviceId: service.service_id,
                    staffId: service.main_staff_id,
                    // coStaffId: service.coworker_staff_id,
                    coStaffId: getCoStaffId(service.coworker_staff_id),
                    // tax: service.tax,
                    quantity: service.quantity,
                    price: service.price,
                    minVal: service.price,
                    total: service.total,
                }));
            } else {

                servicesData = [{
                    serviceId: '',
                    staffId: '',
                    coStaffId: [],
                    // tax: '18',
                    quantity: '1',
                    price: '',
                    total: '',
                }];
            }

            // let wallet;
            let status;
            if (billingData?.is_wallet_used == 1) {
                setShowWallet('');
                status = true;
            } else {
                setShowWallet('none');
                status = false;
            }

            if (billingData?.special_discount) {
                setshowDiscount('');
            }

            handleCustomerClick(billingData?.get_customer_info?.uuid)

            setFormData({
                name: billingData.get_customer_info?.name,
                phone: billingData.get_customer_info?.phone_number,
                gender: billingData.get_customer_info?.gender,
                orgId: billingData.org_id,
                customerId: billingData.get_customer_info?.id,
                type: 'edit',
                date: billingData?.date,
                time: billingData?.time,
                refId: billingData.get_customer_info?.reference_id,
                wallet: billingData.get_customer_info?.wallet + billingData?.wallet_amount_used,

                billingRemark: billingData?.billing_remark,
                subTotal: billingData?.sub_total,
                billingTax: billingData?.tax,
                discountSymbol: billingData?.discount_symbol,
                discount: billingData?.discount,
                discountedAmount: billingData?.discount_amount,
                specialDiscount: billingData?.special_discount,
                paymentMode: billingData?.payment_mode,
                grandTotal: billingData?.grand_total,
                uuid: billingData?.uuid,
                walletStatus: status,
                multiplePaymentMethods:fields,
                taxEnabled: billingData?.is_tax_include == 0 ? false : true,
                walletAmount: billingData?.wallet_amount_used,
                roundOffGrandTotal: billingData?.round_off_total,

                // Multiple billing service
                services: servicesData

            });
        }

    }, [billingData, selectGlobalOrg]);

    useEffect(() => {
        fetchStaff();
        fetchServices();
        fetchBilling(uuid);
    }, [selectGlobalOrg, uuid]);

    const handleSelectChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].selectValue = e.target.value;
        if(updatedFields[index].selectValue===""){
            updatedFields[index].inputValue=""
        }
        // setFormData({...formData, multiplePaymentMethods:updatedFields})
        setFields(updatedFields);
        const newIsVisible = [...isVisible];
        newIsVisible[index] = true;
        setIsVisible(newIsVisible);
    };

    const handleMultiInputChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].inputValue = e.target.value;
        if (updatedFields[index].selectValue !== "") {
          setFields(updatedFields);
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        }

        if (!isMultiPaymentValid()) {
            newErrors.paymentMode = "Payment mode is required";
            isValid = false;
          setErrors(newErrors);
            return isValid;
          }
          if (!isMultiPaymentAmountValid()) {
            newErrors.paymentMode = "Amount is required.";
            isValid = false;
          setErrors(newErrors);
            return isValid;
          }

        // Check discount based on symbol
        const discount = parseFloat(formData.discount);
        const discountSymbol = formData.discountSymbol;
        const subtotal = parseFloat(formData.subTotal);
        const discountLimitAmount = subtotal * (discountLimit / 100);

        if (discountSymbol === '%') {
            if (discount > discountLimit) {
                newErrors.discount = `Discount cannot exceed ${discountLimit}%`;
                isValid = false;
            }
        } else {
            if (discount > discountLimitAmount) {
                if (formData.specialDiscount === "" || isNaN(formData.specialDiscount) || formData.specialDiscount <= discountLimitAmount || formData.specialDiscount > subtotal) {
                    newErrors.specialDiscount = `Special discount must be a number greater than ${discountLimitAmount} and less than or equal to ${subtotal}`;
                    isValid = false;
                }
            }
        }

        const phoneValue = formData.phone.trim();

        if (!phoneValue) {
            newErrors.phone = "Phone is required";
            isValid = false;
        } else if (phoneValue.length !== 10) {
            newErrors.phone = "Phone number should be 10 numbers";
            isValid = false;
        } else if (!phoneValue.match(/^[6-9]\d{9}$/)) {
            newErrors.phone = "Invalid phone number format";
            isValid = false;
        }

        setErrors(newErrors);

        return isValid;
    };

    const getCoStaffId = (arr) => {
        if (!arr) {
            // Return an empty array or handle the error as needed
            return [];
        }

        const valuesArray = arr.split(',');

        const values = valuesArray.map((el) => Number(el));

        return values;
    };


    const handleInputChange = (e) => {

        const { name, value, type, checked } = e.target;
        const processedValue = type === 'number' ? parseFloat(value) : value;


        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: processedValue,
        }));

        if (name == 'walletStatus') {

            console.log(checked, 'checked');

            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked
            }));
        }

        if (name == 'taxEnabled') {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const [errors, setErrors] = useState({});

    const isMultiPaymentValid = () => {
        return fields.every(field => (field.selectValue !== "" || field.selectValue.trim() !== "") );
      };
    
      const isMultiPaymentAmountValid = () => {
        return fields.every(field => ( (Number(field.inputValue) !== 0 && field.selectValue!=="") && ((field.inputValue !== "" && field.selectValue!=="") || (field.inputValue.trim() !== "" && field.selectValue!==""))));
      };





    // useEffect(() => {

    //     handleCustomerClick();


    // }, [selectGlobalOrg,uuid]);

    const handleCustomerClick = async (id) => {

        try {
            const result = await handleEditCustomer(id);

            if (result.data) {
                setSelectedCustomer(result.data?.data)
                // setShowDetail('block')
                // setShowDiv('none')
                // setShowGender('none')
            } else {
                // setShowGender('')
                // setShowDiv('none')
                // setShowDetail('none')
                // setSelectedCustomer([]);
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

   

    const fetchBilling = async (uuid) => {
        try {
            const result = await handleEditBilling(uuid);

            if (result.data) {
                setBillingData(result.data?.data)
                
                if(result.data?.data?.multiple_payment_types){
                    
                    // setBillingData(result.data?.data?.multiple_payment_types)
                    if (result.data?.data?.multiple_payment_types && result.data?.data?.multiple_payment_types.length > 0) {
                        const tempFields = result.data?.data?.multiple_payment_types.map(item => ({
                            selectValue: item?.payment_mode,
                            inputValue: item?.price
                        }));
                        
                        setFields(tempFields);
            
                        const tempIsVisible = Array(tempFields.length).fill(true);
                       
                        setIsVisible(tempIsVisible);
                        
                        let x=formData;
                        x.multiplePaymentMethods=tempFields
                        
                        setFormData({...x})
                    }
                }
               
                
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }
    }




    const fetchStaff = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleStaffListing(initialOrgIds, 'employee', '0');

            if (result.data) {
                setStaffData(result.data?.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    const fetchServices = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        const status = 0;

        try {
            const result = await handleServiceListing(initialOrgIds, status);
            if (result?.data) {
                setServices(result?.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }


    const addRow = () => {
        setFormData({
            ...formData,
            services: [...formData.services, { serviceId: '', coStaffId: '', quantity: '', price: '', total: '' }]
        });
    };

    const removeRow = (index) => {
        setFormData(prevState => {
            if (prevState.services.length <= 1) {
                // Show toast message indicating that at least one service is required
                showToastMessage("error", "At least one service is required");
                return prevState; // Don't modify state
            }

            const updatedServices = [...prevState.services];
            updatedServices.splice(index, 1);

            let subtotal = 0;
            let totalTax = 0;
            let discountAmt = 0;
            const taxEnabled = formData.taxEnabled;

            updatedServices.forEach(service => {
                subtotal += service.price * service.quantity;
                if (taxEnabled == true) {
                    totalTax += (service.price * service.quantity * 18) / 100;
                } else {
                    totalTax += (service.price * service.quantity * 18) / 118
                }
            });

            if (taxEnabled == false) {
                subtotal -= totalTax;
            }


            let grandTotal = subtotal + totalTax;

            const walletAmount = formData.wallet;
            const walletStatus = formData.walletStatus;
            let amountToDeduct;

            // console.log(walletStatus == true && grandTotal != 0,'condtion');

            if (walletStatus == true && grandTotal != 0) {
                amountToDeduct = Math.min(walletAmount, grandTotal);
                grandTotal -= amountToDeduct;
            }

            // Apply discount if available
            let discount = prevState && prevState.discount ? prevState.discount : 0;
            const discountSymbol = prevState && prevState.discountSymbol ? prevState.discountSymbol : '%';

            if (discountSymbol == '%') {
                discountAmt = Math.min(subtotal * (discount / 100), subtotal);
            } else {
                discountAmt = Math.min(discount, subtotal);
            }

            grandTotal -= discountAmt;

            return {
                ...prevState,
                walletAmount: amountToDeduct,
                services: updatedServices,
                roundOffGrandTotal: roundOffAmount(grandTotal),
                subTotal: formatAmount(subtotal),
                billingTax: formatAmount(totalTax),
                grandTotal: formatAmount(grandTotal),
                discount: discount || '0',
                discountedAmount: formatAmount(discountAmt)
            };
        });
    };

    const calculateTotalForRow = (quantity, price, tax) => {
        const totalPrice = parseFloat(quantity) * parseFloat(price);
        // const totalTax = totalPrice * (parseFloat(tax) / 100);
        return formatAmount(totalPrice);
    };

    const handleFieldChange = (fieldName, value, index) => {
        console.log(fieldName, value, index, 'index');

        const walletAmount = formData.wallet;
        const walletStatus = formData.walletStatus;
        const taxEnabled = formData.taxEnabled;

        let amountToDeduct;

        if (fieldName === 'discount') {

            if (parseFloat(value) > parseFloat(discountLimit)) {
                setshowDiscount('');
                if (walletAmount > 0) {
                    setShowWallet('none');
                }

            } else {
                setshowDiscount('none');
                if (walletAmount > 0) {
                    setShowWallet('');
                }

            }

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['specialDiscount']: "",
            }));
        }

        setFormData(prevState => {
            const updatedServices = [...prevState.services];

            if (index !== undefined) {
                updatedServices[index] = {
                    ...updatedServices[index],
                    [fieldName]: value,
                    total: calculateTotalForRow(
                        fieldName === 'quantity' ? value : updatedServices[index]?.quantity,
                        fieldName === 'price' ? value : updatedServices[index]?.price,
                        '18'
                    ),
                    // tax:'18',
                };
            }

            let subtotal = 0;
            let totalTax = 0;
            let discountAmt = 0;

            updatedServices.forEach(service => {

                subtotal += service.price * service.quantity;

                if (fieldName === 'taxEnabled') {

                    if (taxEnabled == false) {
                        totalTax += (service.price * service.quantity * 18) / 100;
                    } else {
                        totalTax += (service.price * service.quantity * 18) / 118;
                    }

                } else {
                    if (taxEnabled == true) {
                        totalTax += (service.price * service.quantity * 18) / 100;
                    } else {
                        totalTax += (service.price * service.quantity * 18) / 118
                    }
                }

            });

            if (fieldName === 'taxEnabled') {
                if (taxEnabled == true) {
                    subtotal -= totalTax;
                }
            } else {
                if (taxEnabled == false) {
                    subtotal -= totalTax
                }
            }

            let grandTotal = subtotal + totalTax;



            // Apply discount if available
            let discount = prevState && prevState.discount ? prevState.discount : "";



            const discountSymbol = prevState && prevState.discountSymbol ? prevState.discountSymbol : '%';

            let discountVar;

            if (parseInt(discount) > parseInt(discountLimit)) {
                discountVar = discountLimit
            } else {
                discountVar = discount;
            }

            if (discountSymbol == '%') {
                discountAmt = Math.min(subtotal * (discountVar / 100), subtotal);
            } else {
                discountAmt = Math.min(discountVar, subtotal);
            }

            grandTotal -= discountAmt;

            let grand = roundOffAmount(grandTotal)

            if (discount <= discountLimit) {
                if (fieldName === 'walletStatus') {

                    if (walletStatus == false && grand != 0) {
                        amountToDeduct = Math.min(walletAmount, grand);
                        grand -= amountToDeduct;
                    }

                } else {
                    if (walletStatus == true && grand != 0) {
                        amountToDeduct = Math.min(walletAmount, grand);
                        grand -= amountToDeduct;
                    }
                }

            }

            return {
                ...prevState,
                walletAmount: amountToDeduct,
                services: updatedServices,
                subTotal: formatAmount(subtotal),
                billingTax: formatAmount(totalTax),
                grandTotal: formatAmount(grandTotal),
                roundOffGrandTotal: grand,
                discount: discount,
                discountedAmount: formatAmount(discountAmt)
            };
        });
    };


    const handleServicecategory = async (option, index) => {
        const id = option.value;
        if (!id) {
            return false;
        }
        try {
            const result = await handleEditService(id);
            if (result.data) {
                const newData = result.data?.data;

                setFormData(prevState => {
                    const updatedServices = [...prevState.services];
                    updatedServices[index] = {
                        ...updatedServices[index],
                        serviceId: newData.id,
                        price: newData.price,
                        quantity: '1',
                        minVal: newData.price,
                        // tax:'18',
                        total: calculateTotalForRow('1', newData.price, '18')
                    };

                    let subtotal = 0;
                    let totalTax = 0;
                    let discountAmt = 0;
                    const taxEnabled = formData.taxEnabled

                    updatedServices.forEach(service => {
                        subtotal += service.price * service.quantity;
                        if (taxEnabled == true) {
                            totalTax += (service.price * service.quantity * 18) / 100;
                        } else {
                            totalTax += (service.price * service.quantity * 18) / 118
                        }
                    });

                    if (taxEnabled == false) {
                        subtotal -= totalTax;
                    }

                    let grandTotal = subtotal + totalTax;

                    const walletAmount = formData.wallet;
                    const walletStatus = formData.walletStatus;
                    let amountToDeduct;

                    // console.log(walletStatus == true && grandTotal != 0,'condtion');

                    if (walletStatus == true && grandTotal != 0) {
                        amountToDeduct = Math.min(walletAmount, grandTotal);
                        grandTotal -= amountToDeduct;
                    }

                    // Apply discount if available
                    let discount = prevState && prevState.discount ? prevState.discount : 0;
                    const discountSymbol = prevState && prevState.discountSymbol ? prevState.discountSymbol : '%';

                    if (discountSymbol == '%') {
                        discountAmt = Math.min(subtotal * (discount / 100), subtotal);
                    } else {
                        discountAmt = Math.min(discount, subtotal);
                    }

                    // console.log(discount,'discount');
                    // console.log(discountAmt,'discountAmt');

                    grandTotal -= discountAmt;

                    return {
                        ...prevState,
                        walletAmount: amountToDeduct,
                        services: updatedServices,
                        subTotal: formatAmount(subtotal),
                        billingTax: formatAmount(totalTax),
                        grandTotal: formatAmount(grandTotal),
                        roundOffGrandTotal: roundOffAmount(grandTotal),
                        discount: discount,
                        discountedAmount: formatAmount(discountAmt)
                    };
                });

            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        }
    };


    const options = [{ value: "", label: "Select Service" }];
    if (services && services.length > 0) {
        const groupedServices = {};
        services.forEach((el) => {
            if (!el.deleted_at) { // Check if deleted_at is null or undefined
                if (!groupedServices[el.service_category_id]) {
                    groupedServices[el.service_category_id] = [];
                }

                groupedServices[el.service_category_id].push({
                    value: el.id,
                    label: el.name,
                    cat_id: el.get_cat_name?.name
                });
            }
        });

        for (const category in groupedServices) {
            options.push({
                label: groupedServices[category][0].cat_id,
                options: groupedServices[category],
            });
        }
    }


    const staffOptions = [{ value: "", label: "Select Staff" }];
    if (staffData && staffData.length > 0) {
        staffData.forEach((el) => {
            if (!el.deleted_at && el.role?.role === 'employee') { // Check if deleted_at is null or undefined and role is 'employee'
                staffOptions.push({
                    value: el.id,
                    label: el.name,
                });
            }
        });
    }



    const handleSubmit = async (e) => {


        e.preventDefault();

        if (validateForm()) {
            setBtn(true)

            try {
                const newFormData = { ...formData, multiplePaymentMethods: fields }
                setFormData(newFormData)
                const result = await handleBilling(newFormData, reason);

                if (result.data) {
                    showToastMessage('success', result.data.message)
                    navigate('/billing-listing')
                    setBtn(false)

                }
            } catch (error) {
                setBtn(false)
                console.log(error);
                if (error.response.status === 404) {
                    const errors = error.response.data.message
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((key) => {
                            errors[key].forEach((message) => {
                                showToastMessage('error', message)
                            });
                        });
                    }
                } else {
                    showToastMessage('error', error.response.data.message)
                }
            }
        }



    }

    return (

        <>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center bg-transparent">
                    <div className="p-header d-flex flex-column gap-2 align-items-start justify-content-start">
                        <h2 className="bodytext-30 fontweight-600 text-dark mb-0">Billing</h2>

                    </div>
                    <NavLink to='/billing-listing' className="button button-primary">View All Invoices</NavLink>
                </div>
                <div className="card-body">
                    <form id="billingform" autoComplete="off">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Mobile Number *</label>
                                            <p className="form-control">{formData.phone}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Customer Name *</label>
                                            <p className="form-control">{formData.name}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" >
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">Gender</label>
                                            <p className="form-control">{formData.gender}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">How did you reach us?</label>
                                            <p className="form-control">{formData.refId}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Date</label>
                                            <p className="form-control">{formData.date}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Time</label>
                                            <p className="form-control">{formData.time}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {console.log('selectedCustomer', selectedCustomer)}
                                <blockquote className="blockquote blockquote-primary customer_detail round-10 ">
                                    <CustomerDetail
                                        selectedCustomer={selectedCustomer}
                                    />
                                </blockquote>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 margintop-5 marginbottom-15">
                                <button type="button" onClick={addRow} id="btn_add_row" className="button button-primary button-icon-left"><i className="fa fa-plus"></i> Add Service</button>
                            </div>
                            <div className="col-md-12 mt-4">

                                {formData.services.map((value, index) => (
                                    <TableListing
                                        key={index}
                                        index={index}
                                        value={value}
                                        services={options}
                                        minVal={value?.minVal}
                                        staffData={staffOptions}
                                        handleServicecategory={handleServicecategory}
                                        handleFieldChange={handleFieldChange}
                                        removeRow={removeRow}
                                        selectedCustomer={selectedCustomer}
                                    />
                                ))}

                            </div>
                        </div>
                        <div className="row justify-content-between mt-2">
                            <div className="col-md-12 d-flex flex-row" id="button_add">
                                <div className="form-group flex-grow-1">
                                    <label for="billing_remark">Billing Remark</label>
                                    <textarea
                                        className="form-control billing_remark"
                                        id="billing_remark"
                                        name="billingRemark"
                                        value={formData.billingRemark}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                    ></textarea>
                                    <div className="col-md-12 mt-4 " id="package">
                                        <table className="table table-bordered table-billing">
                                            <tbody>
                                                <tr>

                                                    <th style={{ width: '50%' }}>Sub Total</th>
                                                    <td className="text-right">
                                                        <span id="subTotal">{formData?.subTotal || 0.00}</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>Tax (18%)</th>
                                                    <td className="text-right">
                                                        <span id="taxTotal">{formData?.billingTax || 0.00}</span>
                                                    </td>
                                                </tr>

                                                <tr className="amount_due tax-include">
                                                    <th>Tax Exclude/Include</th>
                                                    <td className="text-right">
                                                        <div className="form-group mb-0 d-flex justify-content-end">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <input
                                                                    type='checkbox'
                                                                    className="form-check m-0"
                                                                    name='taxEnabled'
                                                                    checked={formData.taxEnabled}
                                                                    onChange={(e) => {
                                                                        handleInputChange(e);
                                                                        handleFieldChange('taxEnabled', e.target.value);
                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th className="paddingbottom-25" style={{ verticalAlign: 'middle' }}>
                                                    Discount
                                                    </th>
                                                    <td className="text-right position-relative paddingbottom-25">
                                                        <div className="discount-wrap">
                                                            <div className="discount-wrap-field">
                                                                <select
                                                                    className="text-right input-xs discount_mode form-select form-control"
                                                                    id="discount_mode"
                                                                    value={formData.discountSymbol}
                                                                    onChange={(e) => {
                                                                        handleInputChange(e);
                                                                        handleFieldChange('discountSymbol', e.target.value);
                                                                    }}

                                                                    name="discountSymbol">
                                                                    <option value="%">%</option>
                                                                    <option value="₹">₹</option>
                                                                </select>
                                                            </div>
                                                            <input
                                                                className="text-right discount input-sm form-control"
                                                                id="discount"
                                                                value={formData.discount}
                                                                name="discount"
                                                                type="text"
                                                                onKeyDown={discountValidate}
                                                                onChange={(e) => {
                                                                    handleInputChange(e);
                                                                    handleFieldChange('discount', e.target.value);
                                                                }}
                                                            />

                                                        </div>
                                                        <div id="discount_value" className="text-start discount-amount">{formData.discountedAmount}</div>
                                                    </td>
                                                </tr>
                                                <tr className="amount_due" style={{ display: showDiscount }} >
                                                    <th>Special Discount:</th>
                                                    <td className="text-right">
                                                        <div className="form-group mb-0">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <input
                                                                    className="text-right discount input-sm form-control"
                                                                    step="any"
                                                                    min="0"
                                                                    max="100"
                                                                    value={formData?.specialDiscount}
                                                                    name="specialDiscount"
                                                                    type="text"
                                                                    onKeyDown={discountValidate}
                                                                    onChange={(e) => {
                                                                        handleInputChange(e);
                                                                    }}
                                                                />

                                                            </div>
                                                            {errors.specialDiscount && (
                                                                <div className="text-danger">{errors.specialDiscount}</div>
                                                            )}
                                                        </div>
                                                        {/* <p >*You can apply a maximum discount of 40%. For discounts exceeding this limit, please submit a request to the admin.</p> */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Payment Mode</th>
                                                    <td className="text-right">
                                                        {fields.map((field, index) => (
                                                            <>  
                                                            <div className={`row ${index > 0 ? "mt-3" :""}`}>
                                                                <div className="col">
                                                                <div className="row">
                                                                    <div className="col">

                                                                    
                                                                <select
                                                                    key={index}
                                                                    className="form-select form-control"
                                                                    name="paymentMode"
                                                                    value={field.selectValue}
                                                                    onChange={(event) =>{
                                                                        handleSelectChange(event, index);
                                                                        handleInputChange(event);
                                                                    }}
                                                                >
                                                                <option value="">Select Payment Mode</option>
                                                                {selectOptions(null, index)}
                                                                </select>
                                                                </div>
                                                                
                                                                {isVisible[index] === true && field.selectValue!=="" && (
                                                                    <div className="col">

                                                                        <input
                                                                            min="1"
                                                                            type="number"
                                                                            className="form-control"
                                                                            defaultValue={field.inputValue}
                                                                            onChange={(e) =>
                                                                                { 
                                                                                    handleMultiInputChange(e, index)
                                                                                    errors.paymentMode = ""
                                                                                }
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                </div>
                                                                </div>
                                                                {(fields.length > 1 && index > 0) ? (
                                                                <div className="col-auto">
                                                                    <button
                                                                    type="button"
                                                                    onClick={(e) =>
                                                                        {
                                                                        const updatedFields = fields.filter((_, i) => i !== index);
                                                                        setFields(updatedFields);
                                                                        }
                                                                    }
                                                                    id="btn_remove_row"
                                                                    className="button button-primary btn-icon"
                                                                    >
                                                                    <i className="fa fa-minus"></i>
                                                                    </button>
                                                                    </div>
                                                                    ) : (
                                                                        <div className="col-auto">   
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                setFields([
                                                                                ...fields,
                                                                                { selectValue: "", inputValue: "" },
                                                                                ])
                                                                            }
                                                                            id="btn_add_row"
                                                                            className="button button-primary btn-icon"
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                        </div>
                                                                    )
                                                                }
                                                                </div>
                                                                
                                                            </>
                                                        ))}
                                                        {errors.paymentMode && (
                                                            <div className="text-danger">
                                                                {errors.paymentMode}
                                                            </div>
                                                        )}
                                                        
                                                    </td>
                                                </tr>

                                                <tr className="amount_due" style={{ display: showWallet }}>
                                                    <th>Use Wallet Amount:</th>
                                                    <td className="text-right">
                                                        <div className="form-group mb-0">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <input
                                                                    type='checkbox'
                                                                    className="form-check m-0"
                                                                    name='walletStatus'
                                                                    checked={formData.walletStatus}
                                                                    onChange={(e) => {
                                                                        handleInputChange(e);
                                                                        handleFieldChange('walletStatus', e.target.value);
                                                                    }}
                                                                />
                                                                <span className="currencySymbol" style={{ display: 'inline-block' }} ></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr className="amount_due">
                                                    <th>Grand Total:</th>
                                                    <td className="text-right">
                                                        <span className="currencySymbol" style={{ display: 'inline-block' }} ></span>
                                                        <span id="grandTotal">{formatAmount(formData?.roundOffGrandTotal)}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            disabled={btn}
                                            className="button button-primary table-billing-btn">
                                            Save Bill
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default EditBilling;
