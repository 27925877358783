import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { handleExpenseCategory } from '../../services/expenseCategory';
import { showToastMessage } from '../../helpers/toastr';
// import { useDispatch, useSelector } from 'react-redux';
// import { handleStaff } from '../../services/staffServices';
Modal.setAppElement('#root')


const ExpensesCategoryModal = ( { modalIsOpen, closeModal, customStyles, type, setIsOpen , fetchData , selectedCategory  } ) => {

  const [btn, setBtn] = useState(false);
    
  const headerText = type === "add" ? 'Add Expense Category' : 'Edit Expense Category';

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: '',
    orgId: '',
    type: '',
  });

  // console.log(type,'type');
  // console.log(selectCustomer,'selectCustomer');
  

  useEffect(() => {

    if (type === 'edit' && selectedCategory  ) {
      setFormData({
        name: selectedCategory.name,
        orgId:  selectedCategory.org_id,
        type: type,
        id:selectedCategory.id
        
      });

    
    } else {
      const newOrgId = document.getElementById('orgId').value;
      setFormData({
        name: '',
        orgId: newOrgId,
        type: type,
      });
     
    }

    setErrors({});
   
  }, [modalIsOpen]);

//   console.log(formData);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Expense Category name is required";
      isValid = false;
    }

   
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
   

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const saveExpenseCategory = async (type) => {

    if (validateForm()) {

        // console.log(formData,'formData()');

        // return;

        setBtn(true);

        try {
          const reason = selectedCategory.reason;
            const result = await handleExpenseCategory(formData,reason);

            // console.log(result,'result');
    
            if (result && result.data && result.data.success === true) {
                showToastMessage('success', result.data.message)
                fetchData()
                closeModal();
                setBtn(false);
            }
    
          } catch (error) {
            console.log(error);
            setBtn(false);
            showToastMessage('error', error.response.data.message)
          }

      
    }
  };


  // handleStaffListing

  return <>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white"
    >
    <div className="d-flex align-items-center justify-content-between">
          <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
          <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close-circle-outline menu-icon"></i></button>
    </div>

      <form className="mx-auto mt-4 form" style={{ maxWidth: '100%' }}>
        <div className="row">
            <div className="col-md-12 col-12">
                <div className="form-group">
                  <label htmlFor="name" className="form-label">Expense Category Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Enter your category name"
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>
            </div>
             <div className="col-md-12 col-12 text-end">
                <button
                type="button"
                    className="button button-primary button-icon-left"
                    disabled={btn}
                    onClick={() => saveExpenseCategory(type)}
                    >
                    Submit
                </button>
             </div> 
        </div>
      </form>
    </Modal>
  </>
}

export default ExpensesCategoryModal;
