import { useEffect, useState } from "react";
import { handleSettingData, handleWhatsAppIntegration } from "../../services/userProfile";
import { showToastMessage } from "../../helpers/toastr";

const WhatsappIntegration = ({selectGlobalOrg}) => {

  // console.log(selectGlobalOrg,'selectGlobalOrgselectGlobalOrg');

  const [data ,setData] = useState([]);

  const [errors ,setErrors] = useState({});


    useEffect(() => {
    
      fetchData()
      
  }, [selectGlobalOrg ]);

    

    const [formData, setFormData] = useState({
        orgId:'',
        apiUrl:'',
        apiKey:'',
      });

      useEffect(() => {

        // console.log("hii");

        if(data != null){
          
          setFormData({
            orgId:data.org_id,
            apiUrl:data.whatsapp_api_url || '',
            apiKey:data.whatsapp_api_key || '',
          });
        }else{
          const newOrgId = document.getElementById('orgId').value;
          setFormData({
            orgId:newOrgId,
            apiUrl:'',
            apiKey:'',
          });
        }

        
         
      }, [selectGlobalOrg,data]);

    const validateForm = () => {

      let isValid = true;
      const newErrors = {};

      if (!formData.apiUrl.trim()) {
          newErrors.apiUrl = "Api url is required";
          isValid = false;
      }

      if (!formData.apiKey.trim()) {
          newErrors.apiKey = "Api key is required";
          isValid = false;
      }

      setErrors(newErrors);

      return isValid;

    }

    const handleInputChange = (e) => {

        const {name,value,type} = e.target;

        setFormData((prevFormData)=>(
            {
                ...prevFormData,
                [name]: value,
        }
        ))

        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
      }));


    }

    const fetchData = async () => {
      const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
      try {
        const result = await handleSettingData(initialOrgIds);

        if (result?.data) {
          setData(result?.data?.data);
        }
        // console.log(data,'resultttttt');

      } catch (error) {
          console.log(error);
          showToastMessage('error', error.response?.data.message);
      }

    }

    const handleSave = async () => {

      if(validateForm()){

        try {
          const result = await handleWhatsAppIntegration(formData);
          // console.log(result);
  
          if (result.data) {
            showToastMessage('success', result.data.message);
          }
          
  
      } catch (error) {
          // console.log(error);
          // showToastMessage('error', error.response?.data.message);
          if (error.response.status === 404) {
            const errors = error.response.data.message;
            if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).forEach((key) => {
                    errors[key].forEach((message) => {
                        showToastMessage('error', message);
                    });
                });
            }
        } else {
            showToastMessage('error', error.response.data.message);
        }
      }

      }

    

    }

    

    return (
    <>
    <form className="mx-auto form mt-4" >
      <div className="card p-4">
      <div className="row">
          
          <div className="col-md-6 col-12">

             <div className="form-group">
                <label htmlFor="apiUrl" className="form-label">API URL:</label>
                 <input
                   type="text"
                   className="form-control"
                   id="apiUrl"
                   name="apiUrl"
                   value={formData.apiUrl}
                   // checked={formData.expenseType === '0'}
                   onChange={(e) => handleInputChange(e)}
                   placeholder="Enter Your WhatsApp API URL"
                 />
                 {errors.apiUrl && (
                   <div className="text-danger">{errors.apiUrl}</div>
                 )}
             </div>
           </div>
            <div className="col-md-6 col-12">
               <div className="form-group">
                 <label htmlFor="apiKey" className="form-label">API KEY:</label>
                 <input
                   type="text"
                   className="form-control"
                   id="apiKey"
                   name="apiKey"
                   // onKeyDown={formatIntger}
                   value={formData.apiKey}
                   // checked={formData.expenseType === '1'}
                   onChange={(e) => handleInputChange(e)}
                   placeholder="Enter Your Whatsapp API KEY"
                 />
                 {errors.apiKey && (
                   <div className="text-danger">{errors.apiKey}</div>
                 )}
               </div>
           </div>
         
           <div className="col-md-12 col-12 text-end">
               <button type="button" 
               // disabled={btn}
               className="button button-primary button-icon-left"
               onClick={() => handleSave()}
               >
               Submit
               </button>
           </div>
       </div>
      </div>
        
      </form>
    </>
    )
    

}

export default WhatsappIntegration;