import Modal from 'react-modal';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const IframeModal = ({ modalIsOpen, closeModal, customStyles, setIsOpen, layout, modalHeading, onCloseAndNavigate }) => {
  const contentToPrint = useRef(null);
  const navigate = useNavigate();

  const headerText = modalHeading || 'Billing';

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => {
      console.log("after printing...");
      closeModal(); // Close the modal after printing
      if (typeof onCloseAndNavigate === 'function') {
        onCloseAndNavigate(); // Call it if it's defined
      }
    },
    removeAfterPrint: true,
  });

  const handleClose = () => {
    closeModal(); // Always close the modal
    if (typeof onCloseAndNavigate === 'function') {
      onCloseAndNavigate(); // Call it if it's defined
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleClose} // Use handleClose
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white invoice-billing"
    >
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
        <button
          className="btn icon-md round-100 bodytext-22 border-0 icontext-darkgrey"
          onClick={handleClose}
          aria-label="Close modal"
        >
          <i className="mdi mdi-close menu-icon"></i>
        </button>
      </div>

      <div ref={contentToPrint}>
        {layout}
      </div>

      <div className="col-md-12 col-12 py-3 text-center">
        <button
          type="button"
          className="button button-primary print-btn"
          onClick={handlePrint}
        >
          Print Invoice
        </button>
      </div>
    </Modal>
  );
};

export default IframeModal;
