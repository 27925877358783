import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const MultipleProductListing = ({ index, services, staffData, removeRow, handleProduct, value , handleFieldChange , errors}) => {

    return (
        <div className="col-md-12" id={index} key={index}>
        <div className="added-card">
        <div className="row">
        <div className="col-sm-4 col-m-4 col-lg-4 col-xl-auto col-xxl">
            <div className="form-group">
              <label>Product</label>
                <Select
                    name="productId"
                    options={services}
                    placeholder={'Select Product'}
                    isSearchable={true}
                    classNamePrefix="form"
                    value={services.reduce((acc, group) => {
                        if (group && group.options) {
                            const selectedOption = group.options.find(option => option.value === value.productId);
                            if (selectedOption) {
                                acc = selectedOption;
                            }
                        }
                        return acc;
                    }, null)}
                    onChange={(option) => {
                        handleProduct(option, index);
                        handleFieldChange('productId', option.value, index)
                    }}
                />
                 {/* {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )} */}
            </div>
        </div>
        
        <div className="col-sm-4 col-m-4 col-lg-4 col-xl-auto col-xxl">
            <div className="form-group">
                <label>Staff</label>
                <Select
                    name="staffId"
                    value={staffData.find(option => option.value === value.staffId)}
                    isSearchable={true}
                    classNamePrefix="form"
                    options={staffData}
                    placeholder={'Select Staff'}
                    onChange={option => handleFieldChange('staffId', option.value, index)}
                />
            </div>
        </div>
      
        <div className="col-sm col-md col-lg col-xl col-xxl">
            <div className="form-group">
                <label>Quantity</label>
                <input
                    name="quantity"
                    type="number"
                    value={value.quantity}
                    className="required form-control calcEvent service_qty input-sm"
                    step="any"
                    min="1"
                    onChange={e => handleFieldChange('quantity', e.target.value, index)}
                />
            </div>
        </div>
        <div className="col-sm col-md col-lg col-xl col-xxl">
            <div className="form-group">
                <label>Price</label>
                <input
                    name="price"
                    type="number"
                    value={value.price || '0.00'}
                    className="required form-control calcEvent service_price input-sm"
                    step="any"
                    min="0"
                    onChange={e => handleFieldChange('price', e.target.value, index)}
                />
            </div>
        </div>
       
        <div className="col-sm-auto col-md-auto col-lg-auto col-xl-auto col-xxl-auto">
            <div className="form-group">
            <label>Total</label>
            <div className="percentage margintop-12">
                <input
                    name="total"
                    type="hidden"
                    value={value.total}
                    className="service_total form-control"
                />
                <span className="service_total_txt">{value.total || '0.00'}</span>
            </div>
            </div>
        </div>
        <div className="col-12 col-sm-12 col-xl-auto col-delete">
            <div className="form-group">
                
                <button type="button" className="button button-primary button-icon" onClick={() => removeRow(index)}>
                    <i class="mdi mdi-delete-empty menu-icon bodytext-24"></i>
                </button>
            </div>
        </div>
    </div>
    </div>
    </div>
    );
};
