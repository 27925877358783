const Footer = () =>{
  return(
  <footer class="footer">
    <div class="container-fluid d-flex justify-content-between">
      <span class="text-muted d-block text-center text-sm-start d-sm-inline-block bodytext-14">Copyright © 2024 Salon Billing. All rights reserved.</span>
      <span class="float-none float-sm-end mt-1 mt-sm-0 text-end bodytext-14 text-muted">Powered by &nbsp;<a href="https://www.hashsoftware.com/" target="_blank" className="text-decoration-none">Hash Software</a></span>
    </div>
  </footer>
)
}
export default Footer;