import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProfileLayout from '../../components/Profile/ProfileLayout';

const Profile = () => {

    const [key, setKey] = useState('profile');

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="profile" title="Profile">
           <ProfileLayout />
      </Tab>
      {/* <Tab eventKey="org" title="Organisation">
          Tab content for Organisation
      </Tab> */}
      <Tab eventKey="change password" title="Change Password" >
          Change Password
      </Tab>
    </Tabs>
  );

}

export default Profile;



