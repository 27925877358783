export function formatDate(dateString) {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Extract day, month, and year components
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  const year = date.getFullYear();

  // Format the date components with leading zeros if necessary
  const formattedDay = (day < 10 ? '0' : '') + day;
  const formattedMonth = (month < 10 ? '0' : '') + month;

  // Construct the formatted date string
  const formattedDate = formattedDay + '-' + formattedMonth + '-' + year;

  return formattedDate;
}