import { useEffect, useState } from 'react';
// import axiosInstance from './axiosConfig';

import Modal from 'react-modal';
import Loading from '../loader/Loading';
import DataTable from 'react-data-table-component';
import { formatAmountSymbol } from '../../helpers/formatAmountSymbol';
import { formatDate } from '../../helpers/formatDate';
import { formatDateTime } from '../../helpers/formatDateTime';
import { formatTime } from '../../helpers/formatTime';
import { paymentOption } from '../../helpers/paymentOption';
import BillingInvoice from '../../pages/InvoicesPrint/BillingInvoice';
import { handleBillingPDF } from '../../services/billingService';
import { showToastMessage } from '../../helpers/toastr';
import wrapModalfn from '../modal/modal';
import IframeModal from '../modal/IframeModal';

Modal.setAppElement('#root')


const HistoryModal = ({ modalIsOpen, closeModal, customStyles, hisData }) => {

    // const {  openModal, setIsOpen, type  } = wrapModalfn();

    const headerText = 'Customer Payment History'
    const secondHeaderText = 'Service History'

    const [pdfData, setPdfData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [secondModalData, setSecondModalData] = useState(null);
    const [activeTab, setActiveTab] = useState('wallet');
    const [iframeModalIsOpen, iframeSetIsOpen] = useState(false);

    function openIframeModal(type) {
        iframeSetIsOpen(true);
    }

    function closeIframeModal() {
        iframeSetIsOpen(false);
    }

    // const handleSecondModalOpen = async (id, orgID) => {
    //     try {
    //         setSecondModalData(hisData?.get_service_billings);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };
    useEffect(() => {
        setLoading(false)
        console.log(hisData, 'hisData');
    }, [hisData]);

    const columns = [
        {
            name: 'Type',
            selector: row => row.type === 0 ? 'Wallet' : 'Service',
        },
        {
            name: 'Date',
            selector: row => formatDate(row.created_at),
        },
        {
            name: 'Time',
            selector: row => formatDateTime(row.created_at),
        },
        {
            name: 'Credit',
            selector: row => row.payment_type === 0 ? getAmount(row) : 0, // Render credit if row type is 0
        },
        {
            name: 'Debit',
            selector: row => row.payment_type === 1 ? getAmount(row) : 0, // Render debit if row type is not 0
        },
        {
            name: 'Balance',
            selector: row => row.type === 0 ? formatAmountSymbol(row?.balance) : row?.balance,
        },
    ];

    const columnsOutstanding = [
        {
            name: 'Type',
            selector: row => 'Outstanding',
        },
        {
            name: 'Date',
            selector: row => formatDate(row.created_at),
        },
        {
            name: 'Time',
            selector: row => formatDateTime(row.created_at),
        },
        {
            name: 'Payment Mode',
            selector: row => paymentOption(row.payment_mode), // Render credit if row type is 0
        },
        {
            name: 'Outstanding',
            selector: row => formatAmountSymbol(Math.abs(row.outstanding_amount)), // Render debit if row type is not 0
        },
        {
            name: 'Payment',
            selector: row => formatAmountSymbol(row.customer_paid_amount), // Render debit if row type is not 0
        },
    ];

    const columnsServiceHistory = [
        {
            name: 'Payment Mode',
            selector: row => paymentOption(row.payment_mode), // Render credit if row type is 0
        },
        {
            name: 'Payment Date',
            selector: row => formatDate(row.date), // Render credit if row type is 0
        },
        {
            name: 'Payment Time',
            selector: row =>row.time, // Render credit if row type is 0
        },
        {
            name: 'Grand Total',
            selector: row => formatAmountSymbol(row?.round_off_total ?? row.grand_total), // Render credit if row type is 0
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="d-flex gap-2">

                    <button
                        className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0"
                        onClick={() => handleDownload(row?.uuid)}
                    >
                        <i className="mdi mdi-eye menu-icon bodytext-24"></i></button>

                </div>
            ),
        },
    ];

    const columnsSecondModal = [
        {
            name: 'Service Name',
            selector: row => row.get_multiple_services.get_service_name.name,
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
        },
    ];

    const getAmount = (row) => {
        switch (row.type) {
            case 0:
                return formatAmountSymbol(row.amount);
            case 1:
                return row.services;
            default:
                return 0;
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const closeSecondModal = () => {
        setSecondModalData(null);
    };


    const handleDownload = async (uuid, pdfType) => {



        try {
            const result = await handleBillingPDF(uuid);

            console.log(result, 'resultresultresult');

            openIframeModal();

            setPdfData(result?.data?.data)

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response?.data.message);
        }

    }

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
                className="custom-modal round-15 bg-white"
            >
                <div class="modal_header">
                    <div className="row align-items-center">
                        <div class="col">
                            <h2 className="modal-title">{headerText}</h2>
                        </div>
                        <div class="col-auto">
                            <button className="btn btn-icon btn-icon-sm btn-close" onClick={closeModal}><i className="mdi mdi-close menu-icon"></i></button>
                        </div>
                    </div>
                </div> 
                <div class="modal_body">
                    <div className="tabs flex-wrap d-flex gap-3 mb-3">
                        <button className={`button bodytext-14 borderwidth-1 bordercolor-dark tab-btn ${activeTab === 'wallet' ? 'active' : ''}`} onClick={() => handleTabChange('wallet')}>Wallet</button>
                        <button className={`button bodytext-14 borderwidth-1 bordercolor-dark tab-btn ${activeTab === 'outstanding' ? 'active' : ''}`} onClick={() => handleTabChange('outstanding')}>Outstanding</button>
                        <button className={`button bodytext-14 borderwidth-1 bordercolor-dark tab-btn ${activeTab === 'serviceHistory' ? 'active' : ''}`} onClick={() => handleTabChange('serviceHistory')}>Service</button>
                    </div>

                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            {activeTab === 'wallet' && (
                                <DataTable
                                    columns={columns}
                                    data={hisData?.get_payment_logs}
                                    pagination
                                    paginationPerPage={25}
                                    paginationServer
                                    paginationTotalRows={hisData?.get_payment_logs?.length}
                                    paginationComponentOptions={{
                                        rangeSeparatorText: 'of',
                                        noRowsPerPage: true,
                                    }}
                                />
                            )}

                            {activeTab === 'outstanding' && (
                                <DataTable
                                    columns={columnsOutstanding}
                                    data={hisData?.get_user_subscription}
                                    pagination
                                    paginationPerPage={25}
                                    paginationServer
                                    paginationTotalRows={hisData?.get_user_subscription?.length}
                                    paginationComponentOptions={{
                                        rangeSeparatorText: 'of',
                                        noRowsPerPage: true,
                                    }}
                                />
                            )}
                            {activeTab === 'serviceHistory' && (
                                <DataTable
                                    columns={columnsServiceHistory}
                                    data={hisData?.get_service_billings || []}
                                    pagination
                                    paginationPerPage={25}
                                    paginationServer
                                    paginationTotalRows={hisData?.get_service_billings?.length || 0}
                                    paginationComponentOptions={{
                                        rangeSeparatorText: 'of',
                                        noRowsPerPage: true,
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>  
            </Modal>
            {/* <Modal
                isOpen={Boolean(secondModalData)} // Open second modal when data is available
                onRequestClose={closeSecondModal} // Close second modal
                style={customStyles}
                shouldCloseOnOverlayClick={false}
                contentLabel="Second Modal"
                className="custom-modal round-15 bg-white"
            >
                {hisData?.get_service_billings ? (
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <h2 className="mb-0 heading-h4 fontweight-500">{secondHeaderText}</h2>
                            <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeSecondModal}><i className="mdi mdi-close menu-icon"></i></button>
                        </div>
                        <BillingInvoice data={secondModalData} />
                    </div>
                ) : (
                    <Loading />
                )}
            </Modal> */}
            <IframeModal
                modalIsOpen={iframeModalIsOpen}
                setIsOpen={iframeSetIsOpen}
                closeModal={closeIframeModal}
                customStyles={customStyles}
                layout={<BillingInvoice data={pdfData} />}

            />
        </>
    );
}

export default HistoryModal;
