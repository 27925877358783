import { useEffect, useState } from "react";
import { handleAdditionalSetting, handleSettingData } from "../../services/userProfile";
import { showToastMessage } from "../../helpers/toastr";
import { discountValidate } from "../../helpers/discountValidate";

const AdditionalSettings = ({selectGlobalOrg}) => {

  // console.log(selectGlobalOrg,'selectGlobalOrgselectGlobalOrg');

  const [data ,setData] = useState([]);

  const [error ,setErrors] = useState({});


    useEffect(() => {
    
      fetchData()
      
  }, [selectGlobalOrg ]);

    

    const [formData, setFormData] = useState({
        orgId:'',
        discount:'',
        cashReportDiscount:'',
      });

      useEffect(() => {

        // console.log("hii");

        if(data != null){
          
          setFormData({
            orgId:data.org_id,
            discount: data.max_discount_limit ?? '0',
            cashReportDiscount:data.cash_report_discount ?? '0',
          });
        }else{
          const newOrgId = document.getElementById('orgId').value;
          setFormData({
            orgId:newOrgId,
            discount:'0',
            cashReportDiscount:'0',
          });
        }

        
         
      }, [selectGlobalOrg,data]);

    const validateForm = () => {

      let isValid = true;
      const newErrors = {};

      if (!formData.discount) {
        newErrors.discount = "Discount is required";
        isValid = false;
    } else if (isNaN(parseFloat(formData.discount))) {
        newErrors.discount = "Discount must be a valid number";
        isValid = false;
    } else if (parseFloat(formData.discount) === 0) {
        newErrors.discount = "Discount cannot be zero";
        isValid = false;
    }

    if (!formData.cashReportDiscount) {
      newErrors.cashReportDiscount = "Cash Report Discount is required";
      isValid = false;
  } else if (isNaN(parseFloat(formData.cashReportDiscount))) {
      newErrors.cashReportDiscount = "Cash Report Discount must be a valid number";
      isValid = false;
  } else if (parseFloat(formData.cashReportDiscount) === 0) {
      newErrors.cashReportDiscount = "Cash Report Discount cannot be zero";
      isValid = false;
  }
    
    

    //   if (!formData.apiKey.trim()) {
    //       newErrors.apiKey = "Api key is required";
    //       isValid = false;
    //   }

      setErrors(newErrors);

      return isValid;

    }

    const handleInputChange = (e) => {

        const {name,value,type} = e.target;

        setFormData((prevFormData)=>(
            {
                ...prevFormData,
                [name]: value,
        }
        ))

        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
      }));


    }

    const fetchData = async () => {
      const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
      try {
        const result = await handleSettingData(initialOrgIds);

        if (result?.data) {
          setData(result?.data?.data);
        }
        // console.log(data,'resultttttt');

      } catch (error) {
          console.log(error);
          showToastMessage('error', error.response?.data.message);
      }

    }

    const handleSave = async () => {

      if(validateForm()){

        try {
          const result = await handleAdditionalSetting(formData);
          // console.log(result);
  
          if (result.data) {
            showToastMessage('success', result.data.message);
          }
          
  
      } catch (error) {
          // console.log(error);
          // showToastMessage('error', error.response?.data.message);
          if (error.response.status === 404) {
            const errors = error.response.data.message;
            if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).forEach((key) => {
                    errors[key].forEach((message) => {
                        showToastMessage('error', message);
                    });
                });
            }
        } else {
            showToastMessage('error', error.response.data.message);
        }
      }

      }

    

    }

    

    return (
    <>
    <form className="mx-auto form mt-4" >
        <div className="card p-4">
        <div className="row">
          
          <div className="col-md-6 col-12">

             <div className="form-group">
                <label htmlFor="discount" className="form-label">Maximum Discount:</label>
                 <input
                   type="text"
                   className="form-control"
                   id="discount"
                   name="discount"
                   value={formData.discount}
                   onKeyDown={discountValidate}
                   onChange={(e) => handleInputChange(e)}
                   placeholder="Enter Discount"
                 />
                 {error.discount && (
                   <div className="text-danger">{error.discount}</div>
                 )}
             </div>
           </div>
           <div className="col-md-6 col-12">

        <div className="form-group">
          <label htmlFor="discount" className="form-label">Cash Report Discount:</label>
            <input
              type="text"
              className="form-control"
              id="cashReportDiscount"
              name="cashReportDiscount"
              value={formData.cashReportDiscount}
              onKeyDown={discountValidate}
              onChange={(e) => handleInputChange(e)}
              placeholder="Enter Cash Report Discount"
            />
            {error.cashReportDiscount && (
              <div className="text-danger">{error.cashReportDiscount}</div>
            )}
        </div>
        </div>
            {/* <div className="col-md-6 col-12">
               <div className="form-group">
                 <label htmlFor="apiKey" className="form-label">API KEY:</label>
                 <input
                   type="text"
                   className="form-control"
                   id="apiKey"
                   name="apiKey"
                   // onKeyDown={formatIntger}
                   value={formData.apiKey}
                   // checked={formData.expenseType === '1'}
                   onChange={(e) => handleInputChange(e)}
                   placeholder="Enter Your Whatsapp API KEY"
                 />
                 {errors.apiKey && (
                   <div className="text-danger">{errors.apiKey}</div>
                 )}
               </div>
           </div> */}
         
           <div className="col-md-12 col-12 text-end">
               <button type="button" 
               // disabled={btn}
               className="button button-primary button-icon-left"
               onClick={() => handleSave()}
               >
               Submit
               </button>
           </div>
       </div>
        </div>
        
      </form>
    </>
    )
    

}

export default AdditionalSettings;