import axiosInstance from "./axiosConfig";

const handleServiceCategory = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-service-category?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleServiceCategoryListing = async (formData,page, per_page, searchText) => {
  try {
    const response = await axiosInstance.get(`get-service-category-listing?orgId=${formData}`, {
      params: {
          page: page,
          per_page: per_page,
          searchText: searchText,
      },
  });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditServiceCategory = async (id) => {
  try {
    const response = await axiosInstance.get(`edit-service-category?id=${id}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteServiceCategory = async (id,reason) => {
  try {
    const response = await axiosInstance.get(`delete-service-category?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleServiceCategory,
  handleServiceCategoryListing,
  handleEditServiceCategory,
  handleDeleteServiceCategory
};