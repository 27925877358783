import axiosInstance from './axiosConfig';

const handleProductBilling = async (formData, reason) => {
  try {
    const response = await axiosInstance.post(`save-product-billing?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



const handleEditProductBilling = async (uuid) => {
  try {
    const response = await axiosInstance.post('edit-product-billing', uuid);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// const handleCustomerSearch = async (formData, orgId) => {
//   try {
//     const response = await axiosInstance.get(`get-customer-search?value=${formData}&orgId=${orgId}`);
//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// const handleProductBillingListing = async (orgId, page, per_page, searchText) => {
//   try {
//     const response = await axiosInstance.get(`get-product-billing-listing?orgId=${orgId}&page=${page}&per_page=${per_page}&searchText=${searchText}`);

//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };
// const handleProductBillingListing = async (orgId,page,per_page,searchText,filterData) => {
//   try {
//     const response = await axiosInstance.get(`get-product-billing-listing?orgId=${orgId}&page=${page}&per_page=${per_page}&searchText=${searchText}&filterData=${filterData}`);
//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };
const handleProductBillingListing = async (orgId, status,filterData,page,per_page,searchText) => {

  // if(filterData){
  //   var url = `get-billing-listing?filterData=${filterData}`;
  // }else{
    var url = `get-product-billing-listing?orgId=${orgId}&status=${status}&filterData=${filterData}&page=${page}&per_page=${per_page}&searchText=${searchText}`;
  // }

  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteProductBilling = async (uuid, reason) => {
  try {
    const response = await axiosInstance.get(`delete-product-billing?uuid=${uuid}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleProductBillingPDF = async (uuid) => {
  try {
    const response = await axiosInstance.get(`download-billing-pdf?uuid=${uuid}`
      // , {
      //   responseType: 'blob',
      //   headers: {
      //     Accept: 'application/pdf',
      //     'Content-Type': 'application/pdf',
      //   },
      // }
    );
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const handleGetProductBillingHistory = async (orgID,productId) => {
  try {
    const response = await axiosInstance.get(`get-purchase-history?orgID=${orgID}&productId=${productId}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export {
  handleProductBilling,
  handleProductBillingListing,
  handleDeleteProductBilling,
  handleProductBillingPDF,
  handleEditProductBilling,
  handleGetProductBillingHistory,
};