import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { showToastMessage } from '../../helpers/toastr';
import { handleServiceListing } from '../../services/service';
// import { showToastMessage } from '../../../helpers/toastr';
// import { handleServiceCategoryListing } from '../../../services/serviceCategory.js';
// import { handleService } from '../../../services/service.js';
Modal.setAppElement('#root')
import Select from 'react-select';
import { handlePackage } from '../../services/packageServices';
import { formatIntger } from '../../helpers/formatIntger';


const PackageModal = ({ modalIsOpen, closeModal, customStyles, type, setIsOpen, selectGlobalOrg, fetchData, selectedPackage }) => {

  const [showForm, setShowForm] = useState(0);

  const [btn, setBtn] = useState(false);

  const headerText = type === "add" ? 'Add Package' : 'Edit Package';

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: '',
    reminder: '',
    price: '',
    customerGet: '',
    serviceId: '',
    totalServices: '',
    packageType: '',
    orgId: '',
    type: '',
  });

  const [services, setServices] = useState([]);

  useEffect(() => {

    fetchServices();

  }, [selectGlobalOrg]);

  const fetchServices = async () => {

    const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
    const status = 0;

    try {
      const result = await handleServiceListing(initialOrgIds, status);
      if (result?.data) {
        setServices(result?.data.data)
      }

    } catch (error) {
      console.log(error);
      showToastMessage('error', error.response.data.message)
    }

  }


  const options = [{ value: "", label: "Select Service" }];
  if (services && services.length > 0) {

    const groupedServices = {};


    services.forEach((el) => {
      if (!el.deleted_at) { // Check if deleted_at is null or undefined
        if (!groupedServices[el.service_category_id]) {
          groupedServices[el.service_category_id] = [];
        }

        groupedServices[el.service_category_id].push({
          value: el.id,
          label: el.name,
          cat_id: el.get_cat_name?.name
        });
      }
    });

    for (const category in groupedServices) {
      options.push({
        label: groupedServices[category][0].cat_id,
        options: groupedServices[category],
      });
    }
  }



  const handleShowForm = (value) => {

    if (value == 0) {

      setFormData({
        ...formData,
        serviceId: '',
        totalServices: '',

      });

    } else {
      setFormData({
        ...formData,
        customerGet: '',
      });
    }

    setShowForm(value)

  }

  useEffect(() => {


    if (type === 'edit' && selectedPackage) {

      handleShowForm(selectedPackage?.package_type)

      setFormData({
        id: selectedPackage.id,
        name: selectedPackage.name,
        reminder: selectedPackage.package_validity,
        price: selectedPackage.customer_pay,
        orgId: selectedPackage.org_id,
        status: selectedPackage.status == 0 ? true : false,
        type: type,
        customerGet: selectedPackage.customer_get,
        serviceId: selectedPackage.service_id,
        totalServices: selectedPackage.total_service,
        packageType: String(selectedPackage.package_type),
      });
    } else {
      const newOrgId = document.getElementById('orgId').value;

      handleShowForm(0)

      setFormData({
        name: '',
        reminder: '',
        price: '',
        orgId: newOrgId,
        status: 0,
        type: type,
        customerGet: '',
        serviceId: '',
        totalServices: '',
        packageType: '0',
      });
    }

    setErrors({});

  }, [selectedPackage, modalIsOpen]);

  // useEffect(() => {

  //     if (modalIsOpen === true) {
  //         fetchServiceCategory()
  //         const newOrgId = document.getElementById('orgId').value;
  //         setFormData((prevFormData) => ({
  //             ...prevFormData,
  //             ['categoryID']: "",
  //             ['orgId']: newOrgId,
  //         }));
  //     }

  // }, [selectGlobalOrg]);

  // const fetchServiceCategory = async () => {

  //     const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

  //     const type = '0';

  //     try {
  //         const result = await handleServiceCategoryListing(initialOrgIds, type);
  //         if (result.data) {
  //             setOptions(result.data.data)
  //         }

  //     } catch (error) {
  //         console.log(error);
  //         showToastMessage('error', error.response.data.message)
  //     }


  // }

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Package name is required";
      isValid = false;
    }

    const price = String(formData.price).trim();
    if (!price) {
      newErrors.price = "Customer Pay is required";
      isValid = false;
    } else if (Number(price) === 0) {
      newErrors.price = "Customer Pay cannot be zero";
      isValid = false;
    }

    if (showForm == 0) {
      const customerGet = String(formData.customerGet).trim();
      if (!customerGet) {
        newErrors.customerGet = "Customer Get is required";
        isValid = false;
      } else if (Number(customerGet) === 0) {
        newErrors.customerGet = "Customer Get cannot be zero";
        isValid = false;
      }
    } else {
      const serviceId = String(formData.serviceId).trim();
      if (!serviceId) {
        newErrors.serviceId = "Service name is required";
        isValid = false;
      }

      const totalServices = String(formData.totalServices).trim();
      if (!totalServices) {
        newErrors.totalServices = "Service get is required";
        isValid = false;
      } else if (Number(totalServices) === 0) {
        newErrors.totalServices = "Service get cannot be zero";
        isValid = false;
      }
    }

    const reminder = String(formData.reminder).trim();
    if (!reminder) {
      newErrors.reminder = "Service reminder is required";
      isValid = false;
    } else if (Number(reminder) === 0) {
      newErrors.reminder = "Service reminder cannot be zero";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const processedValue = type === 'number' ? parseFloat(value) : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: processedValue,
    }));

    if (name === 'status') {

      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: checked
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const savePackage = async (type) => {

    // console.log(formData,'formData');

    if (validateForm()) {

      setBtn(true);

      try {
        const reason = selectedPackage.reason;
        const result = await handlePackage(formData, reason);

        console.log(result, 'result');

        if (result.data) {
          showToastMessage('success', result.data.message)
          fetchData();
          closeModal();
          setBtn(false);
        }

      } catch (error) {
        console.log(error);
        setBtn(false);
        showToastMessage('error', error.response.data.message)
      }



    }
  };

  return < >
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white"
    >
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
        <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close menu-icon"></i></button>
      </div>
      <form className="mx-auto form mt-4" style={{ maxWidth: '100%' }}>
        <div className="row">
          <div className="row">
            <div className="form-group">
              <label className="form-label">Package Type:</label>
              <div className="form-radio-group">
                <div className="form-radio form-radio-box">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="basic"
                    name="packageType"
                    value="0"
                    checked={formData.packageType === '0'}
                    onChange={
                      (e) => {
                        handleShowForm(e.target.value)
                        handleInputChange(e)
                      }
                    }
                  />
                  <label htmlFor="basic" className="form-check-label">
                    <span class="radio">
                      <i class="mdi mdi-check menu-icon"></i>
                    </span>
                    Amount Based
                  </label>
                </div>
                <div className="form-radio form-radio-box">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="premium"
                    name="packageType"
                    value="1"
                    checked={formData.packageType === '1'}
                    onChange={
                      (e) => {
                        handleShowForm(e.target.value)
                        handleInputChange(e)
                      }
                    }
                  />
                  <label htmlFor="premium" className="form-check-label">
                    <span class="radio">
                      <i class="mdi mdi-check menu-icon"></i>
                    </span>
                    Service Based
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="name" className="form-label">Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your package name"
              />
              {errors.name && (
                <div className="text-danger">{errors.name}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="price" className="form-label">Customer Pay:</label>
              <input
                type="text"
                className="form-control"
                id="price"
                name="price"
                onKeyDown={(e) => formatIntger(e)}
                value={formData.price}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your price."
              />
              {errors.price && (
                <div className="text-danger">{errors.price}</div>
              )}
            </div>
          </div>

          {(showForm == '0') ? (
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label htmlFor="customerGet" className="form-label">Customer Get:</label>
                <input
                  type="text"
                  className="form-control"
                  id="customerGet"
                  name="customerGet"
                  onKeyDown={(e) => formatIntger(e)}
                  value={formData.customerGet}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="Customer Get"
                />
                {errors.customerGet && (
                  <div className="text-danger">{errors.customerGet}</div>
                )}
              </div>
            </div>
          ) :

            (
              <>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label htmlFor="serviceId" className="form-label">Service:</label>

                    {console.log(formData.serviceId, 'formData.serviceId')}

                    <Select
                      name="serviceId"
                      options={options}
                      placeholder={'Select Service'}
                      isSearchable={true}
                      className="form-select-wrap"
                      classNamePrifix="form"
                      value={options.flatMap(option => option.options).find(op => op && op.value === formData.serviceId)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : "";
                        setFormData({
                          ...formData,
                          serviceId: selectedValue
                        });
                      }}
                    />



                    {errors.serviceId && (
                      <div className="text-danger">{errors.serviceId}</div>
                    )}

                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label htmlFor="totalServices" className="form-label">Services Get:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="totalServices"
                      name="totalServices"
                      onKeyDown={(e) => formatIntger(e)}
                      value={formData.totalServices}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Services Get"
                    />
                    {errors.totalServices && (
                      <div className="text-danger">{errors.totalServices}</div>
                    )}
                  </div>
                </div>
              </>
            )

          }

          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="reminder" className="form-label">Service Reminder:</label>
              <input
                type="text"
                className="form-control"
                id="reminder"
                name="reminder"
                onKeyDown={(e) => formatIntger(e)}
                value={formData.reminder}
                onChange={(e) => handleInputChange(e)}
                placeholder="After how many months"
              />
              {errors.reminder && (
                <div className="text-danger">{errors.reminder}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="status" className="form-label">Status:</label>
              <div class="form-check">

                <input
                  type='checkbox'
                  className="form-check"
                  id='status'
                  name='status'
                  value={formData.status}
                  checked={formData.status}
                  onChange={(e) => handleInputChange(e)}
                />
                <label for="status">
                  <span class="checkbox">
                    <i class="mdi mdi-check menu-icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12 text-end">
            <button type="button" disabled={btn}
              className="button button-primary button-icon-left"
              onClick={() => savePackage(type)}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </>
}

export default PackageModal;