import axiosInstance from "./axiosConfig";

const handleExpense = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-expense?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleExpenseListing = async (orgId,formData,page,per_page,searchText) => {
  try {
    const response = await axiosInstance.get(`get-expense-listing?orgId=${orgId}&filterData=${formData}&page=${page}&per_page=${per_page}&searchText=${searchText}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleCategoryFilter = async (orgId,formData) => {
  try {
    const response = await axiosInstance.get(`get-category-expense-listing?orgId=${orgId}&filterData=${formData}`);
    return response;
  } catch (error) {
    console.log('test',error);
    throw error;
  }
};

const handleEditExpense = async (id) => {
  try {
    const response = await axiosInstance.get(`edit-expense?id=${id}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteExpense = async (id,reason) => {
  try {
    const response = await axiosInstance.get(`delete-expense?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleExpense,
  handleExpenseListing,
  handleCategoryFilter,
  handleEditExpense,
  handleDeleteExpense
};