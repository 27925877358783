import React, { useEffect, useState } from "react";
// import { handleEditCustomer } from "../../../services/customerServices";
// import { CustomerDetail } from "../../../components/appointments/CustomerDetail";
// import { handleStaffListing } from "../../../services/staffServices";
// import { handleEditService, handleServiceListing } from "../../../services/service";
// import { formatAmount } from "../../../helpers/formatAmount";
import { NavLink, useNavigate } from "react-router-dom";
import { MemberShipListing } from "../../components/sellMemberShip/MemberShipListing";
import { Listing } from "../../components/appointments/Listing";
import { showToastMessage } from "../../helpers/toastr";
import { CustomerDetail } from "../../components/appointments/CustomerDetail";
import { formatAmount } from "../../helpers/formatAmount";
import { handleStaffListing } from "../../services/staffServices";
import { handleServiceListing } from "../../services/service";
import { handleCustomerSearch } from "../../services/billingService";
import { handleEditCustomer } from "../../services/customerServices";
import { handleEditPackage, handlePackageListing } from "../../services/packageServices";
import { roundOffAmount } from "../../helpers/roundOffAmount";
import { validatePhone } from "../../helpers/validatePhone";
import { handleMembership } from "../../services/membershipService";
import { debounce } from 'lodash';
import { selectOptions } from "../../helpers/paymentOption";
import { useSelector } from "react-redux";
import checkPermission from "../../helpers/checkPermission";

const SellMembership = ({ selectGlobalOrg }) => {


    const userData = useSelector((state) => state.userData);
    const [showDiv, setShowDiv] = useState('none');
    const [showGender, setShowGender] = useState('');
    const [showDetail, setShowDetail] = useState('none');
    const [searchdata, setSearchData] = useState([]);
    const [value, setValue] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [packages, setPackages] = useState([]);
    const [btn, setBtn] = useState(false);
    const [errors, setErrors] = useState({});
    console.log("errors -- ", errors)
    const [nameInputFocus, setNameInputFocus] = useState(false);
    const [contactInputFocus, setContactInputFocus] = useState(false)
    const navigate = useNavigate();

    const [totalCustomerPaid, setTotalCustomerPaid] = useState(0);
    const [isVisible, setIsVisible] = useState([false]);
    

    const [formData, setFormData] = useState({



        // customer
        name: '',
        phone: '',
        email: '',
        gender: 'male',
        orgId: '',
        refId: '',
        type: 'add',


        // Service Billing
        billingRemark: '',
        subTotal: '0.00',
        tax: 18,
        billingTax: '0.00',
        paymentMode: '',
        customerPaid: 0,
        outStandingAmount: '',
        grandTotal: '0.00',
        taxEnabled: false,
        roundOffTotal: 0,
        expirationDate: '1',
        customExpirationDate: '',
        multiplePaymentMethods: "",
        // Multiple billing service

        packages: [
            {
                packageId: '',
                staffId: '',
                coStaffId: [],
                customerGet: 0,
                customerPay: 0,
                packageType: '',
                servicesLeft: '',
                serviceID: '',
            }
        ]
    });

    const [fields, setFields] = useState([
        { selectValue: "", inputValue: "" },
    ]);

    const handleSelectChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].selectValue = e.target.value;
        if(updatedFields[index].selectValue===""){
            updatedFields[index].inputValue=""
        }
        // setFormData({...formData, multiplePaymentMethods:updatedFields})
        setFields(updatedFields);
        const newIsVisible = [...isVisible];
        newIsVisible[index] = true;
        setIsVisible(newIsVisible);
    };

    const handleMultiInputChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].inputValue = e.target.value;
    
        if (updatedFields[index].selectValue !== "") {
            setFields(updatedFields);
    
            // Calculate the total using reduce
            const total = updatedFields.reduce((acc, field) => {
                return acc + Number(field.inputValue || 0); // Ensure the input value is treated as a number
            }, 0);
    
            setTotalCustomerPaid(total);
            formData.customerPaid = total;
            formData.outStandingAmount = formData.roundOffTotal - formData.customerPaid;
        }
    };
    

    const addSelectBox = () => {
        setFields([...fields, { selectValue: "", inputValue: "" }]);
    };
      
    const isMultiPaymentValid = () => {
        return fields.every(field => (field.selectValue !== "" || field.selectValue.trim() !== "") );
      };

      
      const isMultiPaymentAmountValid = () => {
        return fields.every(field => (((field.inputValue !== "" && field.inputValue > 0) && field.selectValue!=="") || (field.inputValue.trim() !== "" && field.selectValue!=="")));
      };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        }

        if (!formData.customerPaid) {
            newErrors.customerPaid = "Customer paid amount can not be empty and zero.";
            isValid = false;
        }

        if (!isMultiPaymentValid()) {
            newErrors.paymentMode = "Payment mode is required";
            isValid = false;
          setErrors(newErrors);
            return isValid;
          }
          if (!isMultiPaymentAmountValid()) {
            newErrors.paymentMode = "Amount is required.";
            isValid = false;
          setErrors(newErrors);
            return isValid;
          }

        if (formData.expirationDate === 'custom_date' && !formData.customExpirationDate) {

            console.log("hii");

            newErrors.customExpirationDate = "Custom Date is required.";
            isValid = false;
        }


        // Validate phone
        const phoneValue = formData.phone.trim();

        if (!phoneValue) {
            newErrors.phone = "Phone is required";
            isValid = false;
        } else if (phoneValue.length !== 10) {
            newErrors.phone = "Phone number should be 10 digits";
            isValid = false;
        } else if (!phoneValue.match(/^[6-9]\d{9}$/)) {
            newErrors.phone = "Invalid phone number format";
            isValid = false;
        }



        setErrors(newErrors);



        return isValid;
    };

    const handleInputChange = (e) => {

        const { name, value, type, checked } = e.target;
        const processedValue = type === 'number' ? parseFloat(value) : value;


        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: processedValue,
        }));



        if (name == 'taxEnabled') {

            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked
            }));
        }

        //   console.log(formData,'formDataformData');

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };



    const handleKeyUp = (event) => {
        const inputValue = event.target.value;
        setValue(inputValue);
    };

    const searchCustomerNumber = async (value) => {

        const orgID = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleCustomerSearch(value, orgID);

            if (result.data) {
                if (result.data?.data.length > 0) {
                    setSearchData(result.data?.data)
                    setShowDiv('block')
                } else {
                    setShowDiv('none')
                    setSearchData([]);
                }
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //         if (value) {
    //             searchCustomerNumber(value)
    //         } else {
    //             setShowDiv('none')
    //             setSearchData([]);
    //             setValue('')
    //         }
    //     }, 1000);
    //     return () => clearTimeout(delayDebounceFn);
    // }, [value, selectGlobalOrg]);

    useEffect(() => {

        const debouncedFetchData = debounce(searchCustomerNumber, 300);

        const fetchDataOnChange = () => {
            debouncedFetchData(value);
        };

        if (value) {
            fetchDataOnChange(value)
        } else {
            setShowDiv('none')
            setSearchData([]);
            setValue('')
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [value, selectGlobalOrg]);



    const handleCustomerClick = async (id) => {

        try {
            const result = await handleEditCustomer(id);

            if (result.data) {
                setSelectedCustomer(result.data?.data)
                setShowDetail('block')
                setShowDiv('none')
                setShowGender('none')
            } else {
                setShowGender('')
                setShowDiv('none')
                setShowDetail('none')
                setSelectedCustomer([]);
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    useEffect(() => {

        if (selectedCustomer && selectedCustomer.length != 0) {

            setFormData({
                name: selectedCustomer.name,
                phone: selectedCustomer.phone_number,
                uuid: selectedCustomer.uuid,
                gender: selectedCustomer.gender,
                customerId: selectedCustomer.id,
                orgId: selectedCustomer.org_id,
                type: 'add',
                refId: '',

                billingRemark: '',
                subTotal: '0.00',
                tax: 18,
                billingTax: '0.00',
                paymentMode: '',
                customerPaid: '',
                outStandingAmount: '',
                grandTotal: '0.00',
                taxEnabled: false,
                roundOffTotal: 0,
                expirationDate: '1',
                customExpirationDate: '',


                // Multiple billing service
                packages: [
                    {
                        packageId: '',
                        staffId: '',
                        coStaffId: [],
                        customerGet: '',
                        customerPay: '',
                        packageType: '',
                        servicesLeft: '',
                        serviceID: '',
                    }
                ]

            });
        } else {
            const newOrgId = document.getElementById('orgId').value;
            setFormData({

                // customer
                name: '',
                phone: '',
                email: '',
                gender: 'male',
                orgId: newOrgId,
                refId: '',
                type: 'add',


                // Service Billing
                billingRemark: '',
                subTotal: '0.00',
                tax: 18,
                billingTax: '0.00',
                paymentMode: '',
                customerPaid: "",
                outStandingAmount: '',
                grandTotal: '0.00',
                taxEnabled: false,
                roundOffTotal: 0,
                expirationDate: '1',
                customExpirationDate: '',

                // Multiple billing service

                packages: [
                    {
                        packageId: '',
                        staffId: '',
                        coStaffId: [],
                        customerGet: 0,
                        customerPay: 0,
                        packageType: '',
                        servicesLeft: '',
                        serviceID: '',
                    }
                ]
            });
        }

    }, [selectedCustomer]);


    useEffect(() => {

        fetchStaff();
        fetchPackages();

    }, [selectGlobalOrg]);

    //   useEffect for fetch staff members

    const fetchStaff = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleStaffListing(initialOrgIds, 'employee', '0');

            if (result.data) {
                setStaffData(result.data?.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    const fetchPackages = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        const status = 0;

        try {
            const result = await handlePackageListing(initialOrgIds, status);
            if (result?.data) {
                setPackages(result?.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }


    const addRow = () => {
        setFormData({
            ...formData,
            packages: [...formData.packages, { packageId: '', staffId: '', coStaffId: [], customerGet: 0, customerPay: 0, packageType: '', servicesLeft: '', serviceID: '', }]
        });
    };

    const removeRow = (index) => {


        setFormData(prevState => {
            // const updatedPackages = [...prevState.packages];

            if (prevState.packages.length <= 1) {
                // Show toast message indicating that at least one service is required
                showToastMessage("error", "At least one package is required");
                return prevState; // Don't modify state
            }

            const updatedPackages = [...prevState.packages];
            updatedPackages.splice(index, 1);


            let subtotal = 0;
            let totalTax = 0;
            let outStandingAmount = 0;

            const taxEnabled = formData.taxEnabled;

            updatedPackages.forEach(packages => {

                subtotal += parseFloat(packages.customerPay);

                if (taxEnabled == false) {
                    totalTax += (parseFloat(packages.customerPay) * 18) / 118;
                } else {
                    totalTax += (parseFloat(packages.customerPay) * 18) / 100
                }
            })

            if (taxEnabled == false) {
                subtotal -= totalTax;
            }

            let grandTotal = parseFloat(subtotal) + parseFloat(totalTax);

            let grand = roundOffAmount(grandTotal)

            let paid = parseFloat(formData.customerPaid);
            paid = Math.min(paid, grand);
            outStandingAmount += paid - grand


            return {
                ...prevState,
                packages: updatedPackages,
                roundOffTotal: grand,
                customerPaid: totalCustomerPaid,
                outStandingAmount: formatAmount(Math.abs(outStandingAmount)),
                subTotal: formatAmount(subtotal),
                billingTax: formatAmount(totalTax),
                grandTotal: formatAmount(grandTotal),
            };
        });
    };


    const handleFieldChange = (fieldName, value, index) => {
        console.log(fieldName, value, index, 'index');

        setFormData(prevState => {
            const updatedPackages = [...prevState.packages];

            if (index !== undefined) {
                updatedPackages[index] = {
                    ...updatedPackages[index],
                    [fieldName]: value,
                };
            }

            let subtotal = 0;
            let totalTax = 0;
            let outStandingAmount = 0;
            let paid = 0;


            const taxEnabled = fieldName === 'taxEnabled' ? value : formData.taxEnabled;

            updatedPackages.forEach(packages => {
                subtotal += parseFloat(packages.customerPay);

                if (taxEnabled == false) {
                    totalTax += (parseFloat(packages.customerPay) * 18) / 118;
                } else {
                    totalTax += (parseFloat(packages.customerPay) * 18) / 100
                }
            });

            if (taxEnabled == false) {
                subtotal -= totalTax;
            }



            let grandTotal = parseFloat(subtotal) + parseFloat(totalTax);

            let grand = roundOffAmount(grandTotal)

            if (fieldName === 'customerPaid') {
                paid = Math.min(value, grand);
                outStandingAmount += parseFloat(paid) - grand;
            }
            else {
                outStandingAmount += paid - grand
            }


            return {
                ...prevState,
                packages: updatedPackages,
                roundOffTotal: grand,
                customerPaid: totalCustomerPaid,
                outStandingAmount: formatAmount(Math.abs(outStandingAmount)),
                subTotal: formatAmount(subtotal),
                billingTax: formatAmount(totalTax),
                grandTotal: formatAmount(grandTotal),

            };
        });


    };


    const handlePackage = async (option, index) => {
        const id = option.value;
        if (!id) {
            return false;
        }
        try {
            const result = await handleEditPackage(id);
            if (result.data) {
                const newData = result.data?.data;

                setFormData(prevState => {
                    const updatedPackages = [...prevState.packages];
                    updatedPackages[index] = {
                        ...updatedPackages[index],
                        packageId: newData.id,
                        customerGet: newData.package_type == 0 ? newData.customer_get : newData.total_service,
                        customerPay: newData.customer_pay,
                        packageType: newData.package_type,
                        servicesLeft: newData.package_type == 1 ? newData.total_service : '',
                        serviceID: newData.package_type == 1 ? newData.service_id : '',
                    };

                    let subtotal = 0;
                    let totalTax = 0;
                    let outStandingAmount = 0;
                    const taxEnabled = formData.taxEnabled;


                    updatedPackages.forEach(packages => {

                        subtotal += parseFloat(packages.customerPay);

                        if (taxEnabled == false) {
                            totalTax += (parseFloat(packages.customerPay) * 18) / 118;
                        } else {
                            totalTax += (parseFloat(packages.customerPay) * 18) / 100
                        }
                    })

                    if (taxEnabled == false) {
                        subtotal -= totalTax;
                    }

                    let grandTotal = parseFloat(subtotal) + parseFloat(totalTax);


                    let grand = roundOffAmount(grandTotal)

                    outStandingAmount += grand - grand

                    return {
                        ...prevState,
                        packages: updatedPackages,
                        roundOffTotal: grand,
                        customerPaid: totalCustomerPaid,
                        outStandingAmount: formatAmount(Math.abs(outStandingAmount)),
                        subTotal: formatAmount(subtotal),
                        billingTax: formatAmount(totalTax),
                        grandTotal: formatAmount(grandTotal),

                    };
                });

            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        }
    };


    const options = [{ value: "", label: "Select Package" }];

    if (packages && packages.length > 0) {
        packages.forEach((el) => {
            if (el.deleted_at === null) {
                options.push({
                    value: el.id,
                    label: el.name,
                });
            }
        });
    }



    const staffOptions = [{ value: "", label: "Select Staff" }];
    if (staffData && staffData.length > 0) {
        staffData.forEach((el) => {
            if (!el.deleted_at && el.role?.role === 'employee') { // Check if deleted_at is null or undefined and role is 'employee'
                staffOptions.push({
                    value: el.id,
                    label: el.name,
                });
            }
        });
    }



    const handleSubmit = async (e) => {

        if (validateForm()) {

            setBtn(true)

            try {
                const newFormData = { ...formData, multiplePaymentMethods: fields }
                setFormData(newFormData);
                const result = await handleMembership(newFormData);

                if (result.data) {
                    showToastMessage('success', result.data.message)
                    setShowDiv('none')
                    setShowGender('')
                    setSearchData([])
                    setBtn(false)
                    navigate('/membership-listing')
                }
            } catch (error) {
                setBtn(false)
                console.log(error, 'error');
                if (error?.response?.status === 404) {
                    const errors = error.response.data.message
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((key) => {
                            errors[key].forEach((message) => {
                                showToastMessage('error', message)
                            });
                        });
                    }
                } else {
                    showToastMessage('error', error.response.data.message)
                }
            }

        }


    }
    const triggerBackspace = (e) => {
        const key = e.keyCode || e.which;


        if (key === 8) {
            setSelectedCustomer([]);

        }


        if (e.ctrlKey && key === 88) {
            setSelectedCustomer([]);

        }
    };

    return (

        <>

            <div className="card">
                <div className="card-header bg-transparent">
                     <div className="row align-items-center">
                        <div className="col">
                            <h2 className="card-title heading-h3 mb-0">Sell Memberships</h2>
                        </div>
                        <div className="col-auto">
                            {checkPermission(userData, 'View memberships') && (
                                <NavLink to='/membership-listing' className="btn btn-primary mt-2">View All Memberships</NavLink>
                            )}
                        </div>
                     </div>
                </div>
                <div className="card-body">
                    <form id="billingform" autoComplete="off">
                        <div className="row">
                            <div className="col-xl-8 col-lg-7 col-md-12">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Mobile Number *</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="phone"
                                                value={formData.phone}

                                                onChange={(e) => handleInputChange(e)}
                                                onKeyDown={(e) => {
                                                    validatePhone(e);
                                                    triggerBackspace(e);
                                                }}
                                                onKeyUp={handleKeyUp}
                                                onFocus={()=>{setContactInputFocus(true), setNameInputFocus(false)}}
                                                className="form-control cust_mob" id="phone" placeholder="Enter Phone Number" />
                                            {contactInputFocus && <ul className="typeahead dropdown-menu"
                                                style={{ display: showDiv }}>
                                                <Listing
                                                    searchdata={searchdata}
                                                    handleCustomerClick={(id) => handleCustomerClick(id)}
                                                />
                                            </ul>}
                                            {errors.phone && (
                                                <div className="text-danger">{errors.phone}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Customer Name *</label>
                                            <input
                                                type="text"
                                                name="name"
                                                required=""
                                                className="form-control required name"
                                                value={formData.name}
                                                onKeyUp={handleKeyUp}  
                                                onChange={(e) => handleInputChange(e)}
                                                onFocus={()=>{setContactInputFocus(false), setNameInputFocus(true)}}
                                                id="name"
                                                placeholder="Enter Customer Name" />
                                            {nameInputFocus && <ul className="typeahead dropdown-menu"
                                                style={{ display: showDiv }}>
                                                <Listing
                                                    searchdata={searchdata}
                                                    handleCustomerClick={(id) => handleCustomerClick(id)}
                                                />
                                            </ul>}
                                            {errors.name && (
                                                <div className="text-danger">{errors.name}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6" style={{ display: showGender }}>
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">Gender</label>
                                            <select
                                                name="gender"
                                                id="gender"
                                                value={formData.gender}
                                                onChange={(e) => handleInputChange(e)}
                                                className="cust_gender form-control form-select">
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">How did you reach us?</label>
                                            <select
                                                className="form-control form-select"
                                                name="refId"
                                                value={formData.refId}
                                                onChange={(e) => handleInputChange(e)}
                                            >
                                                <option value="">Select Reference</option>
                                               
                                                <option value="insta">Instagram</option>
                                                <option value="fb">Facebook</option>
                                                <option value="walkin">Walkin</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">Select Expiration Date</label>
                                            <select
                                                className="form-control form-select"
                                                name="expirationDate"
                                                value={formData.expirationDate}
                                                onChange={(e) => handleInputChange(e)}
                                            >
                                                <option value="1">One Month</option>
                                                <option value="3">Three Month</option>
                                                <option value="6">Six Month</option>
                                                <option value="12">1 Year</option>
                                                <option value="24">2 Year</option>
                                                <option value="custom_date">Custom Date</option>
                                            </select>
                                        </div>
                                    </div>

                                    {formData.expirationDate === 'custom_date' && (

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="Customer">Expiration Date *</label>
                                                <input
                                                    type="date"
                                                    name="customExpirationDate"
                                                    className="form-control"
                                                    value={formData.customExpirationDate}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Enter Date"

                                                />

                                                {errors.customExpirationDate && (
                                                    <div className="text-danger">{errors.customExpirationDate}
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                    )}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 col-md-12">
                                <blockquote className="blockquote blockquote-primary customer_detail round-10">
                                    <CustomerDetail
                                        selectedCustomer={selectedCustomer}
                                    />
                                </blockquote>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-end">
                                <button type="button" onClick={addRow} id="btn_add_row" className="btn btn-primary btn-icon-left"><i className="fa fa-plus"></i> Add Package</button>
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="table-billing-form" id="item_table">
                                    <div className="row">
                                        {formData.packages.map((value, index) => (
                                            <MemberShipListing
                                                key={index}
                                                index={index}
                                                value={value}
                                                // formData={formData}
                                                packages={options} // Pass your options here
                                                staffData={staffOptions} // Pass your staffOptions here
                                                handlePackage={handlePackage}
                                                handleFieldChange={handleFieldChange}
                                                removeRow={removeRow}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-12 col-md-12" id="button_add">
                                <div className="form-group">
                                    <label for="billing_remark">Billing Remark</label>
                                    <textarea
                                        className="form-control billing_remark"
                                        id="billing_remark"
                                        name="billingRemark"
                                        value={formData.billingRemark}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-md-12 mt-4" id="package">
                                <div className="overflow-auto">
                                <table className="table table-bordered table-billing">
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '50%' }}>Sub Total</th>
                                            <td className="text-right">
                                                <span id="subTotal">{formData?.subTotal || 0.00}</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Tax (18%)</th>
                                            <td className="text-right">
                                                <span id="taxTotal">{formData?.billingTax || 0.00}</span>
                                            </td>
                                        </tr>

                                        <tr className="amount_due tax-include"   >
                                            <th>Tax Exclude/Include</th>
                                            <td className="text-right">
                                                <div className="form-group mb-0 d-flex justify-content-end">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <input
                                                            type='checkbox'
                                                            className="form-check m-0"
                                                            name='taxEnabled'
                                                            checked={formData.taxEnabled}
                                                            onChange={(e) => {
                                                                handleInputChange(e);
                                                                handleFieldChange('taxEnabled', e.target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>



                                        <tr>
                                            <th>Payment Mode</th>
                                            <td className="text-right">
                                                {fields.map((field, index) => (
                                                    <>
                                                    <div className={`row flex-nowrap ${index > 0 ? "mt-3" :""}`}>
                                                     
                                                        <div className="col col-xxl col-lg col-md">
                                                             <select
                                                                className="payment_mode form-select form-control pe-5 form-field-w"
                                                                name="paymentMode"
                                                                value={field.selectValue}
                                                                onChange={(e) => {
                                                                    handleSelectChange(e, index);
                                                                    handleInputChange(e);
                                                                }}
                                                            >
                                                                <option value="">Select Payment Mode</option>
                                                                {selectOptions(null, index)}
                                                                {/* <option value="cash">Cash</option>
                                                            <option value="cc">Card</option>
                                                        
                                                            <option value="gpay">Gpay/UPI</option> */}
                                                            </select>
                                                             {isVisible[index] === true && field.selectValue!=="" && (
                                                           
                                                                <input
                                                                min="1"
                                                                className="form-control mt-2"
                                                                type="number"
                                                                onChange={(e) =>
                                                                    { 
                                                                        handleMultiInputChange(e, index)
                                                                        errors.paymentMode = ""
                                                                       }
                                                                }
                                                            />
                                                         
                                                            
                                                        )}
                                                        </div>
                                                        <div className="col-auto">
                                                       
                                                        
                                                            {(fields.length > 1 && index > 0) ? (
                                                                
                                                                    <button
                                                                        type="button"
                                                                        onClick={(e) =>
                                                                            {
                                                                            const updatedFields = fields.filter((_, i) => i !== index);
                                                                            setFields(updatedFields);
                                                                            }
                                                                        }
                                                                        id="btn_remove_row"
                                                                        className="button button-primary btn-icon"
                                                                    >
                                                                        <i className="fa fa-minus"></i>
                                                                    </button>
                                                               
                                                            ) : (
                                                               
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            setFields([
                                                                            ...fields,
                                                                            { selectValue: "", inputValue: "" },
                                                                            ])
                                                                        }
                                                                        id="btn_add_row"
                                                                        className="button button-primary btn-icon"
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                            
                                                            )}
                                                        </div>
                                                        </div>
                                                    </>
                                                ))}
                                                {errors.paymentMode && (
                                                    <div className="text-danger">
                                                        {errors.paymentMode}
                                                    </div>
                                                )}
                                                
                                                {/* <button
                                                    type="button"
                                                    onClick={() =>
                                                        setFields([
                                                        ...fields,
                                                        { selectValue: "", inputValue: "" },
                                                        ])
                                                    }
                                                    id="btn_add_row"
                                                    className="button button-primary button-icon-left"
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </button> */}
                                            </td>
                                        </tr>

                                        <tr className="amount_due">
                                            <th>Customer Pay:</th>
                                            <td className="text-right">
                                                <div className="form-group mb-0">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <input
                                                            className="text-right discount input-sm form-control"

                                                            value={formData?.customerPaid}
                                                            name="customerPaid"
                                                            type="text"
                                                            disabled
                                                            onKeyDown={(e) => validatePhone(e)}
                                                            onChange={(e) => {
                                                                handleInputChange(e);
                                                                handleFieldChange('customerPaid', e.target.value);
                                                            }}
                                                        />

                                                    </div>
                                                    {errors.customerPaid && (
                                                        <div className="text-danger">{errors.customerPaid}</div>
                                                    )}
                                                </div>

                                            </td>
                                        </tr>
                                        <tr className="amount_due">
                                            <th>Grand Total:</th>
                                            <td className="text-right">
                                                <span className="currencySymbol" style={{ display: 'inline-block' }} ></span>
                                                <span id="grandTotal">{formData?.roundOffTotal || 0.00}</span>
                                            </td>
                                        </tr>

                                        <tr className="amount_due">
                                            <th>Outstanding:</th>
                                            <td className="text-right">
                                                <span className="currencySymbol" style={{ display: 'inline-block' }} ></span>
                                                <span id="outStandingAmount">{formData?.customerPaid ? (formData.roundOffTotal - formData.customerPaid).toFixed(2) : "0.00"}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <button
                                    type="button"
                                    // value="Save Bill &amp; Print" 
                                    onClick={handleSubmit}
                                    disabled={btn}
                                    className="btn btn-primary table-billing-btn" >Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default SellMembership;
