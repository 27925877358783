import { useEffect, useState } from "react";
import {  handleOrganisationData, handleOrganisation  } from "../../services/userProfile";
import { showToastMessage } from "../../helpers/toastr";

const Organisation = ({selectGlobalOrg}) => {

  const [error ,setErrors] = useState({});

  const [data ,setData] = useState([]);


    useEffect(() => {
    
      fetchData()
      
  }, [selectGlobalOrg ]);

    

    const [formData, setFormData] = useState({
        orgId:'',
        name:'',
        address:'',
        legalName:'',
        gstNo:'',
      });

      useEffect(() => {

        // console.log("hii");

        if(data != null){
          
          setFormData({
            orgId:data.id,
            name:data.name || '',
            address:data.address || '',
            legalName:data.legal_name || '',
            gstNo:data.gst_no || '',
          });
        }else{
          const newOrgId = document.getElementById('orgId').value;
          setFormData({
            orgId:newOrgId,
            name:'',
            address:'',
            legalName:'',
            gstNo:'',
          });
        }

        
         
      }, [selectGlobalOrg,data]);

 

    const handleInputChange = (e) => {

        const {name,value,type} = e.target;

        setFormData((prevFormData)=>(
            {
                ...prevFormData,
                [name]: value,
        }
        ))

        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
      }));


    }

    const validateForm = () => {

      // name:'',
      // address:'',
      // legalName:'',
      // gstNo:'',

      let isValid = true;
      const newErrors = {};

      console.log(formData,'formData');

      if (!formData.name.trim()) {
        newErrors.name = "Organisation name is required";
        isValid = false;
     }

      if (!formData.address.trim()) {
        newErrors.address = "Address is required";
        isValid = false;
      }

      if (!formData.gstNo.trim()) {
          newErrors.gstNo = "GST number is required";
          isValid = false;
      }

      if (!formData.legalName.trim()) {
          newErrors.legalName = "Legal Name is required";
          isValid = false;
      }

      setErrors(newErrors);

      return isValid;

    }

    const fetchData = async () => {
      const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
      try {
        const result = await handleOrganisationData(initialOrgIds);

        if (result?.data) {
          setData(result?.data?.data);
        }
        // console.log(data,'resultttttt');

      } catch (error) {
          console.log(error);
          showToastMessage('error', error.response?.data.message);
      }

    }

    const handleSave = async () => {

      if(validateForm()){
        try {
          const result = await handleOrganisation(formData);
          if (result.data) {
              showToastMessage('success', result.data.message);
          }
  
      } catch (error) {
          if (error.response.status === 404) {
              const errors = error.response.data.message;
              if (errors && Object.keys(errors).length > 0) {
                  Object.keys(errors).forEach((key) => {
                      errors[key].forEach((message) => {
                          showToastMessage('error', message);
                      });
                  });
              }
          } else {
              showToastMessage('error', error.response.data.message);
          }
      }
      }

    

    }

    

    return (
    <>
    <form className="mx-auto form mt-4" >
    <div className="card p-4">
    <div className="row">
          
          <div className="col-md-6 col-12">

             <div className="form-group">
                <label htmlFor="name" className="form-label">Orgainsation Name:</label>
                 <input
                   type="text"
                   className="form-control"
                   id="name"
                   name="name"
                   value={formData.name}
                   // checked={formData.expenseType === '0'}
                   onChange={(e) => handleInputChange(e)}
                   placeholder="Enter Your Orgainsation Name"
                 />
                 {error.name && (
                   <div className="text-danger">{error.name}</div>
                 )}
             </div>
           </div>
           <div className="col-md-6 col-12">

             <div className="form-group">
                <label htmlFor="address" className="form-label">Address:</label>
                 <input
                   type="text"
                   className="form-control"
                   id="address"
                   name="address"
                   value={formData.address}
                   // checked={formData.expenseType === '0'}
                   onChange={(e) => handleInputChange(e)}
                   placeholder="Enter Your Address"
                 />
                 {error.address && (
                   <div className="text-danger">{error.address}</div>
                 )}
             </div>
           </div>
            <div className="col-md-6 col-12">
               <div className="form-group">
                 <label htmlFor="gstNo" className="form-label">GST Number:</label>
                 <input
                   type="text"
                   className="form-control"
                   id="gstNo"
                   name="gstNo"
                   // onKeyDown={formatIntger}
                   value={formData.gstNo}
                   // checked={formData.expenseType === '1'}
                   onChange={(e) => handleInputChange(e)}
                   placeholder="Enter GST Number"
                 />
                 {error.gstNo && (
                   <div className="text-danger">{error.gstNo}</div>
                 )}
               </div>
           </div>

           <div className="col-md-6 col-12">
               <div className="form-group">
                 <label htmlFor="legalName" className="form-label">Legal Name:</label>
                 <input
                   type="text"
                   className="form-control"
                   id="legalName"
                   name="legalName"
                   // onKeyDown={formatIntger}
                   value={formData.legalName}
                   // checked={formData.expenseType === '1'}
                   onChange={(e) => handleInputChange(e)}
                   placeholder="Enter Legal Name"
                 />
                 {error.legalName && (
                   <div className="text-danger">{error.legalName}</div>
                 )}
               </div>
           </div>
         
           <div className="col-md-12 col-12 text-end">
               <button type="button" 
               // disabled={btn}
               className="button button-primary button-icon-left"
               onClick={() => handleSave()}
               >
               Submit
               </button>
           </div>
       </div>
    </div>
        
      </form>
    </>
    )
    

}

export default Organisation;