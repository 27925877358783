
import { useState } from "react";

const wrapModalfn = () => {

    const [type, setType] = useState('');

    const [modalIsOpen, setIsOpen] = useState(false);

    const customStyles = {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh', // Set a maximum height for the content
        overflowY: 'auto', // Enable vertical scrolling
      },
    };
    

    function openModal(type) {
      setType(type)
      setIsOpen(true);
    }

      function closeModal() {
        setIsOpen(false);
      }

      return {
        customStyles,
        openModal,
        closeModal,
        modalIsOpen,
        setIsOpen,
        type
      }

}

export default wrapModalfn;