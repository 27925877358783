import DataTable from "react-data-table-component";

import { useState, useEffect } from "react";
import wrapModalfn from "../../../components/modal/modal";
import ProductBrandModal from "../../../components/products/ProductBrandModal";
import { handleDeleteProductBrand, handleEditProductBrand, handleProductBrandListing } from "../../../services/productBrandService";
import Loading from "../../../components/loader/Loading";
import { showToastMessage } from "../../../helpers/toastr";


import ConfirmationPopupButton from "../../../components/ConfirmationPopup/ConfirmationPopupButton";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";
import SearchFilter from "../../../components/pagination/SearchFilter";
import { debounce } from 'lodash';
import { useSelector } from "react-redux";
import checkPermission from "../../../helpers/checkPermission";
import showDeletePopup from "../../../helpers/showDeletePopup";
import showOtpVerficationPopup from "../../../helpers/showOtpVerficationPopup";
import { handleGetOtp, handleOtpVerfication } from "../../../services/billingService";
import { conditionalRowStyles } from "../../../helpers/conditionalRowStyles";
import showEditPopup from "../../../helpers/showEditPopup";
import ReasonTooltip from '../../customer/ReasonTooltip.jsx';

const ProductBrand = ({ selectGlobalOrg }) => {
    const userData = useSelector((state) => state.userData);
    const [popupID, setPopupID] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } = wrapModalfn();

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;



    const handlePageChange = page => {
        fetchData(page, perPage, searchValue);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage, searchValue);
    };


    useEffect(() => {
        // if (filterData != undefined) {
        //     const parsedFilterData = JSON.parse(filterData);
        //     if (parsedFilterData.length === 0) {
        //         return;
        //     }
        // }

        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue]);


    const fetchData = async (page, per_page, searchText) => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleProductBrandListing(initialOrgIds, page, per_page, searchText);
            if (result.data) {
                setData(result.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        } finally {
            setLoading(false)
        }

    }

    // console.log(data.data,'Pt.slice is not a functionPt.slice is not a function');

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            sortFunction: (a, b) => a.name.localeCompare(b.name),
        },
    ];

    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.has_latest_delete_request ? row.has_latest_delete_request.reason : '',
            sortable: true,
            cell: row => {
                if (row?.has_latest_delete_request) {
                    const reason = row.has_latest_delete_request.reason;
                  return <ReasonTooltip reason={reason} />;
                } else {
                  return '';
                }
            }
        });
    }

    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {checkPermission(userData, 'Update product-brand') && (
                    <button className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white" onClick={() => {
                        if (row.deleted_at !== null) {
                            showToastMessage('error', 'Record is deleted, you can not edit it.');
                        } else {
                            handleEdit(row.id, 'edit');
                        }
                    }}><i class="mdi mdi-lead-pencil menu-icon bodytext-24"></i></button>
                )
                }
                {checkPermission(userData, 'Delete product-brand') && (
                    <ConfirmationPopupButton
                        handleDelete={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is already deleted, you cannot delete it.');
                            } else {
                                handleDelete(row.id);
                            }
                        }}

                    />)}

                {/* <button className="icon-sm-medium round-100 bg-white icontext-red borderwidth-0" onClick={() => handleDelete(row.id)}><i class="mdi mdi-delete-empty menu-icon bodytext-24"></i></button> */}
                {/* Add more action buttons as needed */}
            </div >
        ),
    });




    const handleDelete = async (id) => {
        const reason = await showDeletePopup();
        if (reason === '' || reason == null) {
            showToastMessage("error", "Reason for deletion is required!");
            return;
        }
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteProductBrand(id, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(currentPage, perPage)
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            }
        } else {
            const requestType = 'delete';
            const result = await handleGetOtp(id, reason, requestType);
            while (true) {
                let otp = await showOtpVerficationPopup('info');
                if (otp !== '' && otp !== null) {
                    try {
                        const otpResult = await handleOtpVerfication(id, otp, reason);
                        showToastMessage('success', 'OTP verified successfully');
                        try {
                            await handleDeleteProductBrand(id, reason);
                            fetchData(currentPage, perPage)
                        } catch (error) {
                            console.log(error);
                            showToastMessage('error', error.response.data.message)
                        }
                        break; // Exit the loop if everything is successful
                    } catch (error) {
                        if (error && error.response && error.response.data && error.response.data.error) {
                            showToastMessage('error', error.response.data.error);
                        } else {
                            showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                        }
                        console.error('Error:', error);
                        otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                    }
                } else {
                    showToastMessage('info', 'Edit operation was cancelled.');
                    break; // Exit the loop if the operation is cancelled
                }
            }
        }



    };


    const handleEdit = async (id, type) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleEditProductBrand(id);

                    if (result.data) {
                        setSelectedCategory(result.data?.data)
                        setSelectedCategory(preServiceCategory => ({
                            ...preServiceCategory,
                            reason: reason,
                        }));
                        openModal(type)
                    }

                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message)
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(id, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(id, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    const result = await handleEditProductBrand(id);

                                    if (result.data) {
                                        setSelectedCategory(result.data?.data)
                                        setSelectedCategory(preServiceCategory => ({
                                            ...preServiceCategory,
                                            reason: reason,
                                        }));
                                        openModal(type)
                                    }
                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message)
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(id, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    const result = await handleEditProductBrand(id);

                                    if (result.data) {
                                        setSelectedCategory(result.data?.data)
                                        setSelectedCategory(preServiceCategory => ({
                                            ...preServiceCategory,
                                            reason: reason,
                                        }));
                                        openModal(type)
                                    }

                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message)
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };

    return <>
            {/* <ConfirmationPopup
                handleDelete={handleDelete}
                popupID={popupID}
                setPopupID={setPopupID}
            /> */}
                <div className="card">
                    <div className="card-header bg-transparent">
                        <div className="row align-items-center">
                            <div className="col">
                                <h2 class="card-title heading-h3 mb-0">Product Brands</h2>
                            </div>
                            <div className="col-auto">
                                {checkPermission(userData, 'Create product-brand') && (
                                    <button type="button"
                                        onClick={() => openModal('add')}
                                        className="btn btn-primary btn-icon-left mt-2">
                                        <i class="fa fa-plus-circle"></i> Add Product Brand
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ProductBrandModal
                            modalIsOpen={modalIsOpen}
                            setIsOpen={setIsOpen}
                            closeModal={closeModal}
                            customStyles={customStyles}
                            fetchData={() => fetchData(currentPage, perPage)}
                            type={type}
                            selectedCategory={selectedCategory}

                        />
                   
                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    <DataTable
                                        columns={columns}
                                        data={data?.data}
                                        pagination
                                        paginationPerPage={25}
                                        paginationServer
                                        paginationTotalRows={data?.total}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        subHeader
                                        subHeaderComponent={<SearchFilter setSearchValue={setSearchValue} searchValue={searchValue} />}
                                        paginationComponentOptions={{
                                            rangeSeparatorText: 'of',
                                            noRowsPerPage: true,
                                        }}
                                        {...(hasAdminRole && { conditionalRowStyles })}
                                    />
                                </>
                            )}
                     
                    </div>
                </div>

    </>

}

export default ProductBrand;